
import React, {useState} from "react";
import styled from 'styled-components';
import Select from 'react-select';

const Container = styled.div`
  .domain-select {
    width: 100%;
    .domain__control--is-disabled {
      background: transparent !important;
      .domain__indicators {
        display: none !important;
      }
    }
    .domain__control {
      box-shadow: none;
      color: black;
      padding: calc(var(--spacing)* 0.5) calc(var(--spacing)* 2);
      background-color: var(--color-white);
      border-color: var(--color-gray-300);
      border-style: var(--tw-border-style);
      border-width: 1px;
      border-radius: var(--radius-md);
      width: 100%;
      --tw-ease: var(--ease-in-out);


      .domain__value-container {
        padding: 2px 0;
      }
      .domain__placeholder {
        color: var(--color-gray1);
        font-size: 1em;
      }
      .domain__single-value {
        color: black; 
        /* font-weight: 400 !important;
        font-family: Roboto; */
        font-size: 1em;
      }
      .domain__indicators {
        .domain__indicator-separator {
          display: none;
        }
        .domain__dropdown-indicator {
          color: black !important;
          font-weight: 400 !important;
          font-family: Roboto;
          opacity: 0.5;
          padding: 0.5rem 0;
        }
      }
    }
    .domain__menu {
      margin-top: 0 !important;
      padding: 0 !important;
      .domain__menu-list {
        padding: 0 !important;
        .domain__option {
          color: black;
          height: 2rem;
          /* font-weight: 400 !important;
          font-family: Roboto; */
          display: flex;
          align-items: center;
          padding-left: 0.5rem;
          padding: 0.5rem;
          font-size: 1em;
          &.domain__option--is-selected {
            color: white;
          }
          &.domain__option--is-focused {
            
          }
        }
      }
    }
  }
`
const DOMAINS = [
  {value: 'Administrative Services', label: 'Administrative Services'},
  {value: 'Advertising', label: 'Advertising'},
  {value: 'Agriculture and Farming', label: 'Agriculture and Farming'},
  {value: 'Apps', label: 'Apps'},
  {value: 'Artificial Intelligence', label: 'Artificial Intelligence'},  
  {value: 'Biotechnology', label: 'Biotechnology'},
  {value: 'Clothing and Apparel', label: 'Clothing and Apparel'},
  {value: 'Cloud Data Services', label: 'Cloud Data Services'},  
  {value: 'Commerce and Shopping', label: 'Commerce and Shopping'},
  {value: 'Community and Lifestyle', label: 'Community and Lifestyle'},  
  {value: 'Consumer Electronics', label: 'Consumer Electronics'},
  {value: 'Consumer Goods', label: 'Consumer Goods'},
  {value: 'Content and Publishing', label: 'Content and Publishing'},
  {value: 'Data and Analytics', label: 'Data and Analytics'},  
  {value: 'Design', label: 'Design'},
  {value: 'Education', label: 'Education'},
  {value: 'Energy', label: 'Energy'},
  {value: 'Events', label: 'Events'}, 
  {value: 'Financial Services', label: 'Financial Services'},
  {value: 'Food and Beverage', label: 'Food and Beverage'},
  {value: 'Gaming', label: 'Gaming'},
  {value: 'Government and Military', label: 'Government and Military'},  
  {value: 'Hardware', label: 'Hardware'},
  {value: 'Health Care', label: 'Health Care'},
  {value: 'Information Technology', label: 'Information Technology'},
  {value: 'Internet Services', label: 'Internet Services'},  
  {value: 'Lending and Investments', label: 'Lending and Investments'},
  {value: 'Manufacturing', label: 'Manufacturing'},
  {value: 'Media and Entertainment', label: 'Media and Entertainment'},
  {value: 'Messaging and Telecommunications', label: 'Messaging and Telecommunications'},  
  {value: 'Mobile', label: 'Mobile'},
  {value: 'Music and Audio', label: 'Music and Audio'},
  {value: 'Natural Resources', label: 'Natural Resources'},
  {value: 'Navigation and Mapping', label: 'Navigation and Mapping'}, 
  {value: 'Other', label: 'Other'},
  {value: 'Payments', label: 'Payments'},  
  {value: 'Platforms', label: 'Platforms'},
  {value: 'Privacy and Security', label: 'Privacy and Security'},
  {value: 'Professional Services', label: 'Professional Services'},
  {value: 'Real Estate', label: 'Real Estate'},    
  {value: 'Sales and Marketing', label: 'Sales and Marketing'},
  {value: 'Science and Engineering', label: 'Science and Engineering'},
  {value: 'Software', label: 'Software'},
  {value: 'Sports', label: 'Sports'},  
  {value: 'Sustainability', label: 'Sustainability'},
  {value: 'Transportation', label: 'Transportation'},
  {value: 'Travel and Tourism', label: 'Travel and Tourism'},   
  {value: 'Video', label: 'Video'},       
  ];

const DomainSelect = ({ onSelect, value }) => {
  const selectedDomain = DOMAINS.find(item => item.value === value) || '';
  const onDomainSelect = (item) => {
    onSelect(item.value);
  }
  return (
    <Container>
      <Select
        className="domain-select"
        classNamePrefix="domain" 
        placeholder="Select a domain" 
        options={DOMAINS || []}
        value={selectedDomain}
        onChange={onDomainSelect}
        isSearchable={true}
        maxMenuHeight={'14rem'}
      />
    </Container>
  );
}

export default DomainSelect;
import React, { useState, useEffect, useRef } from 'react';
import { useAuth } from '../hooks';
import styled from 'styled-components';
import PageContainer from '../components/PageContainerV2';
import { 
  saveMessageTemplate,
  readMessageTemplate
} from "../services";


const TabsContainer = styled.div`
  .tab-header-message  {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
`

const MessageTemplate = () => {
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState('placeholders');
  const [formErrors, setFormErrors] = useState({});
  const [formValues, setFormValues] = useState({
    first_name: '',
    hq_location: '',
    title: '',
    company_name: '',
    company_url: '',
    latest_funding_round: '',
    total_amt_raised: '',
    calendly_url: ''
  });
  const isFirstLoading = useRef(false);
  useAuth();

  const loadMessageTemplate = async () => {
    try {
      const res = await readMessageTemplate();
      if (res?.message_template) {
        clearAll(res?.message_template);
      } else {
        clearAll();
      }
    } catch (err) {
      console.log(err);
      clearAll();
    } 
  }

  useEffect(() => {
    const onPageLoad = async () => {
      isFirstLoading.current = true;
      await loadMessageTemplate();
      setLoading(false);
    } 
    if (!isFirstLoading.current) {
      onPageLoad();
    }
  }, [])

  const clearAll = (data) => {
    setFormValues(data || {
      first_name: '',
      hq_location: '',
      title: '',
      company_name: '',
      company_url: '',
      latest_funding_round: '',
      total_amt_raised: '',
      calendly_url: ''
    });
    setFormErrors({});
    setActiveTab('placeholders');
  }
  
  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormValues({
      ...formValues,
      [id]: value
    });
    
    // Clear error for this field if it exists
    if (formErrors[id]) {
      setFormErrors({
        ...formErrors,
        [id]: false
      });
    }
  };
  
  const handleSave = async () => {
    // Required fields
    const requiredFields = ['first_name', 'title', 'company_name', 'hq_location'];
    
    // Check for empty required fields
    const newErrors: FormErrors = {};
    let hasErrors = false;
    
    requiredFields.forEach(field => {
      if (!formValues[field]) {
        newErrors[field] = true;
        hasErrors = true;
      }
    });
    
    setFormErrors(newErrors);
    
    if (!hasErrors) {
      // Save form data (would typically send to server)
      console.log('Form submitted successfully:', formValues);
      // Add success message or redirect logic here
      try {
        window.scrollTo(0,0);
        setLoading(true);
        clearAll();
        await saveMessageTemplate({...formValues});
        await loadMessageTemplate();
      } catch (err) {
        console.log(err);
      } finally {
        setActiveTab('placeholders');
        setLoading(false);
      }
    }
  };

  return <PageContainer loading={loading} title='Message Template' selected='message-template'>
    <>    
      {/* Tabs */}
      <TabsContainer className="border-b mb-6 max-w-[30rem]">
          <div className="flex">
            <button 
              onClick={() => setActiveTab('placeholders')}
              className={`px-6 cursor-pointer py-3 text-sm font-medium border-b-2 transition-colors ${
                activeTab === 'placeholders' 
                  ? 'text-gray-800 border-green-600' 
                  : 'text-gray-500 border-transparent hover:text-gray-700 hover:border-gray-300'
              }`}
            >
              Placeholders
            </button>
            <button 
              onClick={() => setActiveTab('preview')}
              className={`px-6 cursor-pointer py-3 text-sm font-medium border-b-2 transition-colors ${
                activeTab === 'preview' 
                  ? 'text-gray-800 border-green-600' 
                  : 'text-gray-500 border-transparent hover:text-gray-700 hover:border-gray-300'
              }`}
            >
              Preview
            </button>
          </div>
        </TabsContainer>

      
      {/* Placeholders tab content */}
      {activeTab === 'placeholders' && (
        <div className="max-w-md pb-6 max-w-[30rem]">
          <div className="mb-6 text-sm text-gray-600">
            The values that you provide below will be used to fill in the custom sourcing message we generate for each profile we source
          </div>
          
          <div className="mb-4">
            <label htmlFor="first_name" className="block text-sm font-medium text-gray-700 mb-1">First Name</label>
            <input
              type="text"
              id="first_name"
              className={`w-full px-3 py-2 border ${formErrors.first_name ? 'border-red-500' : 'border-gray-300'} rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-green-500 focus:border-green-500`}
              value={formValues?.first_name || ''}
              onChange={handleInputChange}
            />
            {formErrors.first_name && (
              <p className="mt-1 text-sm text-red-600">First Name is required</p>
            )}
          </div>
          <div className="mb-4">
            <label htmlFor="title" className="block text-sm font-medium text-gray-700 mb-1">Title</label>
            <input
              type="text"
              id="title"
              className={`w-full px-3 py-2 border ${formErrors.title ? 'border-red-500' : 'border-gray-300'} rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-green-500 focus:border-green-500`}
              value={formValues?.title || ''}
              onChange={handleInputChange}
            />
            {formErrors.title && (
              <p className="mt-1 text-sm text-red-600">Title is required</p>
            )}
          </div>
          <div className="mb-4">
            <label htmlFor="company_name" className="block text-sm font-medium text-gray-700 mb-1">Company Name</label>
            <input
              type="text"
              id="company_name"
              className={`w-full px-3 py-2 border ${formErrors.company_name ? 'border-red-500' : 'border-gray-300'} rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-green-500 focus:border-green-500`}
              value={formValues?.company_name || ''}
              onChange={handleInputChange}
            />
            {formErrors.company_name && (
              <p className="mt-1 text-sm text-red-600">Company Name is required</p>
            )}
          </div>
          <div className="mb-4">
            <label htmlFor="company_url" className="block text-sm font-medium text-gray-700 mb-1">Company Url <span className="text-gray-500 font-normal">[Optional]</span></label>
            <input
              type="url"
              id="company_url"
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-green-500 focus:border-green-500"
              value={formValues?.company_url || ''}
              onChange={handleInputChange}
            />
          </div>
          <div className="mb-4">
            <label htmlFor="hq_location" className="block text-sm font-medium text-gray-700 mb-1">HQ Locaton</label>
            <input
              type="text"
              id="hq_location"
              className={`w-full px-3 py-2 border ${formErrors.hq_location ? 'border-red-500' : 'border-gray-300'} rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-green-500 focus:border-green-500`}
              value={formValues?.hq_location || ''}
              onChange={handleInputChange}
            />
            {formErrors.hq_location && (
              <p className="mt-1 text-sm text-red-600">HQ Locaton is required</p>
            )}
          </div>
          
          <div className="mb-4 mt-6">
            <p className="text-sm font-medium text-gray-700">Please provide both or either of these company funding attributes:</p>
          </div>
          
          <div className="flex space-x-4 mb-4">
            <div className="flex-1">
              <label htmlFor="latest_funding_round" className="block text-sm font-medium text-gray-700 mb-1">Latest Funding Round</label>
              <input
                type="text"
                id="latest_funding_round"
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-green-500 focus:border-green-500"
                value={formValues?.latest_funding_round || ''}
                onChange={handleInputChange}
              />
            </div>
            <div className="flex-1">
              <label htmlFor="total_amt_raised" className="block text-sm font-medium text-gray-700 mb-1">Total Amount Raised</label>
              <input
                type="text"
                id="total_amt_raised"
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-green-500 focus:border-green-500"
                value={formValues?.total_amt_raised || ''}
                onChange={handleInputChange}
              />
            </div>
          </div>
          
          <div className="mb-4 mt-6">
            <label htmlFor="calendly_url" className="block text-sm font-medium text-gray-700 mb-1">Your Calendly link <span className="text-gray-500 font-normal">[Optional]</span></label>
            <input
              type="url"
              id="calendly_url"
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-green-500 focus:border-green-500"
              placeholder="https://calendly.com/yourusername"
              value={formValues?.calendly_url || ''}
              onChange={handleInputChange}
            />
          </div>
          <div className="mt-6">
            <button 
              onClick={ handleSave }
              className="bg-green-600 cursor-pointer text-white px-4 py-2 rounded-md hover:bg-green-700 flex items-center">
              Save
            </button>
          </div>
        </div>
      )}
      
      {/* Preview tab content */}
      {activeTab === 'preview' && (
        <div className="max-w-2xl bg-white p-6 border border-gray-200 rounded-md max-w-[30rem]">
          <h3 className="text-lg font-medium text-gray-900 mb-4">Message Preview</h3>
          <p className="text-gray-700 mb-3">
            Hi [First Name],
          </p>
          <p className="text-gray-700 mb-3">
            I noticed you're the [Title] at [Company Name]. I've been following your company since your [Latest Funding Round] round where you raised [Total Amount Raised].
          </p>
          <p className="text-gray-700 mb-3">
            I'd love to connect and discuss how we might work together. Feel free to schedule some time on my calendar: [Your Calendly link]
          </p>
          <p className="text-gray-700">
            Best regards,<br />
            Your Name
          </p>
        </div>
      )}
    </>

  </PageContainer>
};

export default MessageTemplate;
import React, { useState } from 'react';
import { useAuth } from '../hooks';
import PageContainer from '../components/PageContainerV2';
import ToolsCopanyCacheModalV2 from '../components/ToolsCopanyCacheModalV2';
import { toolsReadCompany,toolsSetCompanyCache } from "../services";
import { PlusCircle, Search, Pencil } from 'lucide-react';

const NOT_FOUND = -1;

function validURL(str) {
  var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
  return !!pattern.test(str);
}

const HonestResumeDashboard = () => {
  const [ loading, setLoading ] = useState(false);
  const [ searchValue, setSearchValue ] = useState('');
  const [ companyData, setCompanyData ] = useState(null);
  const [ isRoleModalOpen, setRoleModalOpen ] = useState(false);
  const [ loadingRoleDetails, setLoadingRoleDetails ] = useState(false);

  useAuth();

  const onInputChange = (e) => {
    setSearchValue(e.target.value);
  }

  const onSearchClick = async () => {
    setLoading(true);
    setCompanyData(null);
    try {
      const res = await toolsReadCompany(searchValue);
      if (res?.company_cache) {
        const data = JSON.parse(res?.company_cache);
        data.company_name = searchValue;
        setCompanyData(data);
      } else {
        setCompanyData(NOT_FOUND);
      }
    } catch (err) {
      setCompanyData(NOT_FOUND);
    } finally {
      setLoading(false);
    }
  }

  const onRoleSave = async (data) => {
    setRoleModalOpen(false);
    setLoading(true);
    await toolsSetCompanyCache(data);
    setCompanyData(null);
    setLoading(false);
  }
  
  const onRoleModalClose = () => {
    setRoleModalOpen(false);
  }

  const addCompanyClick = () => {
    setCompanyData(null);
    setRoleModalOpen(true);
  }

  const onEditClick = () => {
    setRoleModalOpen(true);
  }

  const renderRecord = (record) => {
    if (typeof record === 'object' && record !== null) return JSON.stringify(record);
    if (typeof record === 'boolean') return JSON.stringify(record);
    if (validURL(record)) return <a target='__blank' href={record}>{record}</a>
    return record;
  }

  const renderCompanyData = () => {
    if (companyData === null) return null;
    if (companyData === NOT_FOUND) return  (
      <div className="bg-gray-100 p-4 rounded-lg">
        We did not find the company name in the cache.
      </div>
    );
    return <>
      {
        Object.keys(companyData).map(key => (
          <div key={key}>
            <label className='font-semibold'>{key}: </label>
            <span>{renderRecord(companyData[key])}</span>
          </div>
        ))
      }
      <button 
        onClick={ onEditClick }
        className="bg-green-600 cursor-pointer text-white px-4 mt-4 py-2 rounded-md hover:bg-green-700 flex items-center">
        <Pencil className="mr-2" size={20} />
        Edit
      </button>
    </>
  }

  return <>
    <PageContainer tools={true} loading={loading} title='Company Cache' selected='company_cache'>
      <div className="space-y-2 max-w-[60rem]"> 
        <div className="flex justify-start gap-5 mb-6 max-w-[60rem]">
          <input
            type="text"
            id="name"
            name="name"
            onChange={onInputChange}
            placeholder='Company Name'
            value={searchValue}
            className="w-full max-w-[20rem] p-2 border border-gray-300 rounded-md focus:ring-1 focus:ring-green-500 focus:border-green-500"
          />
          <button 
            onClick={ onSearchClick }
            className="bg-green-600 cursor-pointer text-white px-4 py-2 rounded-md hover:bg-green-700 flex items-center">
            <Search className="mr-2" size={20} />
            Search
          </button>
          <button 
            onClick={ addCompanyClick }
            className="bg-green-600 cursor-pointer text-white px-4 py-2 rounded-md hover:bg-green-700 flex items-center">
            <PlusCircle className="mr-2" size={20} />
            Add
          </button>
        </div>
        {renderCompanyData()}
      </div>
    </PageContainer>
    <ToolsCopanyCacheModalV2 
      isOpen={ isRoleModalOpen }
      data={ companyData }
      onSubmit={ onRoleSave }
      onClose={ onRoleModalClose }
      loading={loadingRoleDetails}
    />
  </>

};

export default HonestResumeDashboard;
import React, { useState } from 'react';
import { useAuth } from '../hooks';
import PageContainer from '../components/PageContainerV2';
import { ChevronDown } from 'lucide-react';


const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border-b">
      <button
        className="w-full py-4 flex justify-between items-center text-left"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="text-lg font-medium">{question}</span>
        <ChevronDown 
          className={`w-5 h-5 transition-transform ${isOpen ? 'transform rotate-180' : ''}`}
        />
      </button>
      <div 
        className={`overflow-hidden transition-all ${
          isOpen ? 'max-h-48 pb-4' : 'max-h-0'
        }`}
      >
        <p className="text-gray-600">{answer}</p>
      </div>
    </div>
  );
};

const HonestResumeDashboard = () => {
  useAuth();
  return <PageContainer loading={false} title='Frequently Asked Questions' selected='faq'>
    <div className="space-y-2 max-w-[60rem]">
        <FAQItem 
          question="What qualities do we look for in candidates when sourcing?"
          answer="We evaluate candidate profiles in 4 dimensions: Career Progression, Loyalty, Quality of Employment and Years of Experience. We count number of promotions, number of 3+ year stints(or 2+ depending on stage of career), whether company they worked at was Big tech, VC funded startup, US corporate, Finance and years of experience in different roles. On top of these evaluation dimensions we can also filter on immigration status and quality of education."
        />
        <FAQItem 
          question="How often are new candidates sourced?"
          answer="We source candidates for all active roles before 8am ET, M-F."
        />
        <FAQItem 
          question="When I update sourcing conditions of an existing role, how soon are new candidates sourced?"
          answer="New candidates will be sourced in the next daily run, which happens before 8am ET the following business day."
        />
      </div>
  </PageContainer>
};

export default HonestResumeDashboard;
import React from "react";
import styled from 'styled-components';

const Container = styled.footer`
  padding-left: 11rem;
  .link-container {
    min-width: 15rem;
  }
`

const Footer = () => {
  return (
    <Container className="bg-gray-800 text-white py-12">
      <div className="max-w-6xl mx-auto px-4 flex justify-center">
        <div className="link-container">
          <h4 className="font-bold mb-4">Honest Resume</h4>
          <ul>
            <li><a target='_blank' href='/terms_and_conditions.html' className="text-gray-400 hover:text-white">Terms and conditions</a></li>
            <li><a target='_blank' href='/privacy_policy.html' className="text-gray-400 hover:text-white">Privacy Policy</a></li>
            <li><a target='_blank' href='/cookie_policy.html' className="text-gray-400 hover:text-white">Cookie Policy</a></li>
            <li><span className="text-gray-400 flex items-center gap-1">
            <span style={{fontSize: '1.2rem'}}>©</span> Copyright 2024</span>
            </li>
          </ul>
        </div>
        <div className="link-container">
          <h4 className="font-bold mb-4">Product</h4>
          <ul>
            <li><a target='_blank' href="/pricing" className="text-gray-400 hover:text-white">Pricing</a></li>
          </ul>
        </div>
        <div className="link-container">
          <h4 className="font-bold mb-4">Connect</h4>
          <ul>
            {/* <li><a href="#" className="text-gray-400 hover:text-white">LinkedIn</a></li>
            <li><a href="#" className="text-gray-400 hover:text-white">Twitter</a></li> */}
            <li><a href="/contact" className="text-gray-400 hover:text-white">Contact</a></li>
          </ul>
        </div>
      </div>
    </Container>
  );
}

export default Footer;
import React, { Component, useState, useEffect, useRef } from "react";
import styled from 'styled-components';
import SlidingModal from "./SlidingModal";
import BlueButton from "./BlueButton";
import Select, {createFilter} from 'react-select';
import { Tooltip } from 'react-tooltip';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { FixedSizeList as List } from "react-window";
import { useAtom } from 'jotai';
import { 
  titlesAtom, 
  citiesAtom,
  statesAtom
} from "../atoms";

const HEIGHT = 35;
const MAIN_TITLES = ["Account Executive", "Engineering Manager", "Software Engineer", "Staff Software Engineer"];
class MenuList extends Component {
  render() {
    const { options, children, maxHeight, getValue } = this.props;
    const [value] = getValue();
    const initialOffset = options.indexOf(value) * HEIGHT;

    return (
      <List
        height={maxHeight}
        itemCount={children.length}
        itemSize={HEIGHT}
        initialScrollOffset={initialOffset}
      >
        {({ index, style }) => <div style={style}>{children[index]}</div>}
      </List>
    );
  }
}

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  height: 100%;

  .react-tabs {
    margin-top: 2.5rem;
    .react-tabs__tab-list {
      margin: 1rem 0 1rem;
      border-bottom: 1px solid hsl(0, 0%, 80%);
      font-size: 0.9rem;
      .react-tabs__tab {
        min-height: 2rem;
        box-sizing: border-box;
        line-height: 2rem;
        padding: 0 1rem;
        font-weight: 400;
        font-family: Roboto;
      }
      .react-tabs__tab--selected {
        border-color: hsl(0, 0%, 80%);
      }
    }
  }

  .location-description {
    font-size: 0.9rem;
    margin: 2rem 0 0;
  }

  .i-sign {
    font-size: 0.8rem;
    display: block;
    position: absolute;
    top: 0.05rem;
    right: -1rem;
  }
  .with-tooltip {
    position: relative;
    display: inline-block;
  }
  h3 {
    padding: 0;
    margin: 0;
    font-size: 1.6rem;
    line-height: 2.5rem;
    font-weight: 400;
  }
  h4 {
    padding: 0;
    margin: 0;
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 400;
    margin-top: 3rem;
  }
  span.checkbox-label  {
    opacity: 1;
    display: block;
    font-size: 1rem;
    font-weight: 400;
    color: black;
    padding: 0;
  }
  .contact-form {
    width: 100%;
    padding: 0 0 1.2rem;
    position: relative; 
    flex: 1;   
    .form-item-group {
      display: flex;
      justify-content: space-between;
      gap: 2rem;
      &.YOE {
        justify-content: flex-start;
        .form-item {
          input:focus~.floating-label,
          input:not(:placeholder-shown)~.floating-label
          {
            top: 1.75rem;
            opacity: 1;
            display: block;
            font-size: 1rem;
            font-weight: 500;
            color: black;
            padding: 0;
          }
        }
      }
    }
    .error-message {
      font-size: 0.8rem;
      color: var(--color-red-1);
      margin-top: 0.5rem;
    }
    .form-item {
      flex: 1;
      position: relative;
      margin-top: 0.9rem;
      padding-top: 0.85rem;
      box-sizing: border-box;
      >label {
        box-sizing: border-box;
        overflow: hidden;
      }
      &.stack-focus {
        display: block;
        font-size: 1rem;
        font-weight: 500;
        color: black;
        margin-top: 0rem;
        >div {
          margin-top: 0rem;
          border-bottom: none;
          >label {
            opacity: 1;
            display: block;
            font-size: 1rem;
            font-weight: 500;
            color: black;
            padding: 0;
            margin-bottom: 1rem;
          }
          div.styled-radio-container {
            label {
              font-weight: 400;
              font-size: 1rem;
              margin-top: 0.25rem;
            }
          }
        }
      }

      input, textarea {
        &:active {
          padding: 0;
        }
        border: none;
        outline: none;
        display: block;
        width: 100%;
        border-bottom: 1px solid var(--color-gray-2);
        min-height: 3rem;
        padding: 0;
        font-size: 1.25rem;
        box-sizing: border-box;
        &[type="text"], &[type="number"] {
          all: unset;
          display: block;
          width: 100%;
          margin-top: 1.5rem;
          line-height: 1.75rem;
          min-height: 3rem;
          border-bottom: 1px solid var(--color-gray-2);
        }
        &::placeholder {
          color: var(--color-gray-0);
          font-size: 1.25rem;
          padding: 0;
        }
        &.error {
          border-bottom: 1px solid var(--color-red-1);
        }
        &[type="number"]::-webkit-inner-spin-button {
          opacity: 1 !important;
        }
      }
      span.checkbox-label  {
        opacity: 1;
        display: block;
        font-size: 1rem;
        font-weight: 400;
        color: black;
        padding: 0;
      }
      textarea {
        margin-top: 1rem;
        height: auto; 
        resize: vertical; 
      }
      textarea[name="description"] {
        min-height: 3rem;
        resize: vertical;
      }

      .floating-label {
        position: absolute;
        pointer-events: none;
        color: var(--color-gray1);
        font-size: 1em;
        left: 0;
        bottom: 1rem;
        font-weight: normal;
        padding: 0;
        span {
          font-weight: 300;
          font-size: 0.9rem;
        }
      }
      .sourcing_title-label {
        top: 0.5rem;
        opacity: 1;
        display: block;
        font-size: 1rem;
        font-weight: 400;
        color: black;
        padding: 0;
      }
      textarea:focus~.floating-label,
      textarea:not(:placeholder-shown)~.floating-label,
      input:focus~.floating-label,
      input:not(:placeholder-shown)~.floating-label
      {
        top: 0.75rem;
        opacity: 1;
        display: block;
        font-size: 1rem;
        font-weight: 500;
        color: black;
        padding: 0;
        br, span {
          display: none;
        } 
      }
      textarea:focus~.floating-label,
      textarea:not(:placeholder-shown)~.floating-label {
        top: 0rem;
      }
    }
  }
  button {
    width: 100%;
    height: 2.5rem;
    text-align: center;
    justify-content: center;
  }
  .controls {
    display: flex;
    gap: 1rem;
  }
  .location-container {
    margin-top: 4rem;
    .location-label {
      display: none;
      opacity: 1;
      font-size: 1rem;
      font-weight: 500;
      color: black;
      padding: 0;
      margin-top: -1rem;
    }
    &.filled {
      .location-label {
        display: block;   
      }
    }
    .location-type-select {
      .location__indicators {
        .location__indicator-separator {
          display: none;
        }
      }
      .location__control {
        border: none !important;
        border-bottom: 1px solid var(--color-gray0) !important;
        box-shadow: none !important;
        .location__value-container {
          padding: 0;
          min-height: 3.5rem;
          .location__input {
            min-height: auto !important;
          }
          .location__multi-value {
            background: white;
            border: 1px solid black;
            >div {
              background: white;
              svg {
                opacity: 0.6;
              }
            }
          }
        }
        .location__multi-value__remove:hover {
          background-color: #ccc;
          color: black;
        }
        .location__placeholder {
          color:  var(--color-gray1);
          font-size: 1rem;
          padding: 0;
        }
      }
      .location__menu {
        margin-top: -1px !important;
        padding: 0 !important;
        .location__option {
            font-size: 0.9rem !important;
          }
        .location__menu-list {
          padding: 0 !important;

        }
      }
    }
  }
  
  .sourcing_title-select {
    max-width: 18rem;
    min-width: 18rem;
    .sourcing_title__control--is-disabled {
      background: transparent !important;
      .sourcing_title__indicators {
        display: none !important;
      }
    }
    .sourcing_title__control {
      border: none !important;
      border-bottom: 1px solid var(--color-gray-2) !important;
      box-shadow: none;
      height: 3.4rem;
      color: black;
      font-weight: 400;
      font-family: Roboto;
      padding-left: 0;
      .sourcing_title__value-container {
        padding: 2px 0;
      }
      .sourcing_title__placeholder {
        color: var(--color-gray1);
        font-size: 1em;
      }
      .sourcing_title__single-value {
        color: black; 
        font-weight: 400 !important;
        font-family: Roboto;
        font-size: 1em;
      }
      .sourcing_title__indicators {
        .sourcing_title__indicator-separator {
          display: none;
        }
        .sourcing_title__dropdown-indicator {
          color: black !important;
          font-weight: 400 !important;
          font-family: Roboto;
          opacity: 0.5;
          padding: 0.5rem 0;
        }
      }
    }
    .sourcing_title__menu {
      margin-top: 0 !important;
      padding: 0 !important;
      .sourcing_title__menu-list {
        padding: 0 !important;
        .sourcing_title__option {
          color: black;
          height: 2rem;
          font-weight: 400 !important;
          font-family: Roboto;
          display: flex;
          align-items: center;
          padding-left: 0.5rem;
          padding: 0.5rem;
          font-size: 1em;
          &.sourcing_title__option--is-selected {
            color: white;
          }
          &.sourcing_title__option--is-focused {
            
          }
        }
      }
    }
  }
  .error-red {
    color: var(--color-red-1) !important;
  }
  .error-red-message {
    color: var(--color-red-1) !important;
    margin-top: 1rem;
    font-size: 0.9em;
  }
`

const SourcingRoleModal = ({ isOpen, data, onSubmit, onClose, loading }) => {
  const [formData, setFormData] = useState();
  const [titles] = useAtom(titlesAtom);
  const [states] = useAtom(statesAtom);
  const [cities] = useAtom(citiesAtom);

  useEffect(() => {
    if (isOpen) {
      clearFormData(data);
    }
  }, [data, isOpen])

  const validateFormData = (data) => {
    const validatedData = {...data};
    let isValid = true;
    if (validatedData['title_id']?.value === '') {
      validatedData['title_id'].error = 'This field cannot be empty.';
      isValid = false;
    }
    if (validatedData['name']?.value === '') {
      validatedData['name'].error = 'This field cannot be empty.';
      isValid = false;
    }
    setFormData(validatedData);
    return isValid;
  } 

  const clearFormData = (data) => {
    setFormData({
      title_id: { value: data?.title_id || '', error: false },
      name: { value: data?.name || '', error: false },
      state_ids: { value: data?.state_ids || [], error: false },
      city_ids: { value: data?.city_ids || [], error: false },
      ic_min_in_years: { value: data?.ic_min_in_years || '', error: false },
      principal_min_in_years: { value: data?.principal_min_in_years || '', error: false },
      qa_min_in_years: { value: data?.qa_min_in_years || '', error: false },
      staff_min_in_years: { value: data?.staff_min_in_years || '', error: false },
      devops_min_in_years: { value: data?.devops_min_in_years || '', error: false },
      manager_min_in_years: { value: data?.manager_min_in_years || '', error: false },
      ae_min_in_years: { value: data?.ae_min_in_years || '', error: false },
    });
  }

  const onInputChange = (e) => {
    const data = {...formData};
    const name  = e.currentTarget.name;
    const value = e.currentTarget.value;
    data[name].value = value;
    if (name === 'name') {
      data[name].error = false;
    }
    setFormData(data);
  }

  const onSourcingTitleSelect = (item) => {
    const data = {...formData};
    data['title_id'].value = item.value;
    data['title_id'].error = false;
    setFormData(data);
  }
  
  const getSourcingTitle = (value) => {
    return titles?.find(item => item.value === value);
  }

  const onLocationChange = (values) => {
    const data = {...formData};
    data['state_ids'].value = values.map(item => item.value);
    data['state_ids'].error = false;
    setFormData(data);
  }

  const onCityChange = (values) => {
    const data = {...formData};
    data['city_ids'].value = values.map(item => item.value);
    data['city_ids'].error = false;
    setFormData(data);
  }

  const getLocation = (values) => {
    return states?.filter(item => values.includes(Number(item.value)));
  }

  const getCity = (values) => {
    return cities?.filter(item => values.includes(item.value));
  }

  const onSubmitClick = async () => {
    if (validateFormData(formData)) {
      //setUploading(true);
      const data = {};
      for (let key in formData) {
        data[key] = formData[key]?.value;
      }
      await onSubmit(data);
      clearFormData();
      //setUploading(false);  
    } else {
      setTimeout(() => {
        const errorInput = document.querySelector('div.error-red-message')
        if (errorInput) {
          errorInput.scrollIntoView();
        }
      }, 50)
    }
  }

  const onModalClose = () => {
    onClose();
  }

  const renderExperience = () => {
    const title = titles?.find(item => item.value === formData?.title_id?.value)?.label;

    if (title === 'Account Executive') return <>
      <div className="form-item" style={{marginTop: '0.5rem', maxWidth: '16rem'}}>
        <input 
          placeholder=" "
          name="ae_min_in_years"
          type="number"
          min={0}
          onChange={onInputChange}
          value={formData?.ae_min_in_years.value || ''}
          className={formData?.ae_min_in_years.error ? 'error' : ''}
        />
        <label className="floating-label">Account Executive Min Years</label>
      </div>
    </>

    if (title === 'Engineering Manager') return <>
      <div className="form-item" style={{marginTop: '0.5rem', maxWidth: '16rem'}}>
        <input 
          placeholder=" "
          name="manager_min_in_years"
          type="number"
          min={0}
          onChange={onInputChange}
          value={formData?.manager_min_in_years.value || ''}
          className={formData?.manager_min_in_years.error ? 'error' : ''}
        />
        <label className="floating-label">Engineering Manager Min Years</label>
      </div>
      <div className="form-item" style={{marginTop: '1rem', maxWidth: '16rem'}}>
        <input 
          placeholder=" "
          name="ic_min_in_years"
          type="number"
          min={0}
          onChange={onInputChange}
          value={formData?.ic_min_in_years.value || ''}
          className={formData?.ic_min_in_years.error ? 'error' : ''}
        />
        <label className="floating-label">Software Engineer Min Years</label>
      </div>
    </>

    if (title === 'Staff Software Engineer') return <>
      <div className="form-item" style={{marginTop: '0.5rem', maxWidth: '17rem'}}>
        <input 
          placeholder=" "
          name="ic_min_in_years"
          type="number"
          min={0}
          onChange={onInputChange}
          value={formData?.ic_min_in_years.value || ''}
          className={formData?.ic_min_in_years.error ? 'error' : ''}
        />
        <label className="floating-label">Software Engineer Min Years</label>
      </div>
      <div className="form-item" style={{marginTop: '1rem', maxWidth: '17rem'}}>
        <input 
          placeholder=" "
          name="staff_min_in_years"
          type="number"
          min={0}
          onChange={onInputChange}
          value={formData?.staff_min_in_years.value || ''}
          className={formData?.staff_min_in_years.error ? 'error' : ''}
        />
        <label className="floating-label">Staff Software Engineer Min Years</label>
      </div>
    </>

    if (title === 'Software Engineer') return <>
      <div className="form-item" style={{marginTop: '0.5rem', maxWidth: '16rem'}}>
        <input 
          placeholder=" "
          name="ic_min_in_years"
          type="number"
          min={0}
          onChange={onInputChange}
          value={formData?.ic_min_in_years.value || ''}
          className={formData?.ic_min_in_years.error ? 'error' : ''}
        />
        <label className="floating-label">Software Engineer Min Years</label>
      </div>
    </>
    return null;
  }

  const isLocationEmpty = !formData?.state_ids?.value?.length;
  const isCityEmpty = !formData?.city_ids?.value?.length;
  let whySubmitButtonDisabled;
  if (formData && Object.keys(formData).find(key => !!formData[key].error)) {
    whySubmitButtonDisabled = "Please fill the mandatory fields above.";
  }
  // if (data) {
  //   whySubmitButtonDisabled = "Role updating is under development";
  // }
  const selectedTitle = titles?.find(item => item.value === formData?.title_id?.value)?.label;
  const isExperienceTabVisible =  MAIN_TITLES.includes(selectedTitle);

  return (
    <>
    <SlidingModal 
      onClose={onModalClose} 
      isOpen={isOpen}
      loading={loading}
      modalStyle={{
        width: '40rem',
        minWidth: '40rem',
        padding:'2rem 4.8rem 2rem',
        boxSizing: 'border-box'
      }}>
      <Container>
        <h3>Sourcing Conditions</h3>
        <form className="contact-form">
            <Tabs className="react-tabs" onSelect={() => {}}>
              <TabList>
                <Tab>General</Tab>
                <Tab>Location</Tab>
                {
                  isExperienceTabVisible &&
                  <Tab >Experience</Tab> 
                }
              </TabList>
              <TabPanel>
                <div className="form-item">
                  <input 
                    placeholder="   "
                    name="name"
                    type="text"
                    onChange={onInputChange}
                    value={formData?.name?.value || ''}
                    className={formData?.name?.error ? 'error' : ''}
                  />
                {/* <label className={formData?.name?.error ? 'floating-label error-red' : 'floating-label'}> */}
                <label className={'floating-label'}>
                  Name <span>(for your reference only)</span></label>
                </div>
                {
                  formData?.name?.error &&
                  <div className="error-message">{formData?.name?.error}</div>
                }

                <div className="form-item" style={{minWidth: '10rem', marginTop: '1rem'}}>
                  <Select
                      className="sourcing_title-select"
                      classNamePrefix="sourcing_title" 
                      placeholder="Select a role" 
                      options={titles || []}
                      value={getSourcingTitle(formData?.title_id.value)}
                      onChange={onSourcingTitleSelect}
                      isDisabled={!!data}
                      isSearchable={true}
                      maxMenuHeight={'14rem'}
                    />
                {
                  formData?.title_id?.error &&
                  <div className="error-message">{formData?.title_id?.error}</div>
                }
                </div>
              </TabPanel>
              <TabPanel>
                <div className="location-description">Please select one or many US states and cities you want 
                  us to source profiles from.</div>
                <div className={`form-item location-container ${isLocationEmpty ? 'empty' : 'filled'}`}>
                  <Select
                    className="location-type-select"
                    classNamePrefix="location" 
                    closeMenuOnSelect={false}
                    placeholder="US State" 
                    isValidNewOption={()=>true}
                    // components={animatedComponents}
                    onChange={onLocationChange}
                    value={getLocation(formData?.state_ids?.value || [])}
                    isMulti
                    maxMenuHeight={'14rem'}
                    options={states || []}
                  />
                </div>
                <div className={`form-item location-container ${isCityEmpty ? 'empty' : 'filled'}`}>
                  <Select
                    className="location-type-select"
                    classNamePrefix="location" 
                    closeMenuOnSelect={false}
                    placeholder="US City" 
                    isValidNewOption={()=>true}
                    onChange={onCityChange}
                    value={getCity(formData?.city_ids?.value || [])}
                    isMulti
                    options={cities || []}
                    components={{MenuList}}
                    filterOption={createFilter({ ignoreAccents: false })} 
                    maxMenuHeight={240}
                  />
                </div>
              </TabPanel>
              {
                isExperienceTabVisible &&
                <TabPanel>
                  <div className="location-description">Please provide the minimum years of experience a profile 
                    should have in these roles to qualify. [Optional]</div>
                  {renderExperience()}
                </TabPanel>
              }
            </Tabs>         
        </form>
        <BlueButton style={{marginTop: '1rem'}} onClick={onSubmitClick} disabled={whySubmitButtonDisabled}>
          Apply
        </BlueButton>
        {
          whySubmitButtonDisabled &&
          <div className='error-red-message'>{whySubmitButtonDisabled}</div>
        }
      </Container>  
      <Tooltip id="my-tooltip" />
    </SlidingModal>
    </>
  );
}

export default SourcingRoleModal;
import React from "react";
import styled from 'styled-components';
import { useNavigate } from 'react-router';
import { p2r } from "../utils";
const Container = styled.div`

`

const NavBar = ({hideLogin}) => {
  const navigate = useNavigate();
  const onLoginClick = () => {
    navigate('/login');
  }
  const onLogoClick = () => {
    navigate('/');
  }

  return (
    <nav className="flex justify-between items-center p-6 max-w-6xl mx-auto w-full">
    <div onClick={onLogoClick} className="cursor-pointer text-2xl font-bold text-green-600">Honest Resume</div>
    <div>
      {
        !hideLogin &&
        <button 
          className="cursor-pointer bg-green-600 text-white px-4 py-2 rounded-md hover:bg-green-700"
          onClick={onLoginClick}
        >
          Log In
        </button>
      }
    </div>
  </nav>
  );
}

export default NavBar;
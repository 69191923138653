import React, { useState, useEffect, useRef } from "react";
import styled from 'styled-components';
import { getProfileSourcingMessage } from "../services";
import Spinner from "./Spinner";

const PopupContainer = styled.div`
  background-color: white;
  padding: 1rem;
  min-height: 8rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 450px;
  text-align: center;
  .popup-message {
    font-size: 16px;
    line-height: 1.5;
  }
  .popup-btn {
    margin-top: 20px;
    padding: 10px 25px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
  }
  .popup-btn:hover {
    background-color: #45a049;
  }
`

const ViewMessage = ({ data, onCopy, onClose }) => {
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError]  = useState(false);
  const isLoading = useRef(false);
  
  useEffect(() => {
    const loadMessage = async () => {
      try {
        isLoading.current = true;
        setLoading(true);
        const res = await getProfileSourcingMessage(data.role_id, data.profile_id);
        if (res?.sourcing_message) {
          setMessage(res.sourcing_message)
          setError(false);
        } else {
          setError(true);
        }
      } catch (err) {
        console.log(err);
        setError(true);
      } finally {
        setLoading(false);
        isLoading.current = false;
      }
    }
    if (data && !isLoading.current) {
      loadMessage();
    }
  }, [data]);



  const renderContent = () => {
    if (loading) return (
      <PopupContainer>
        <Spinner color="var(--color-green-700)" secondaryColor="rgba(51,128,63,0.4)" size="2rem"/>
      </PopupContainer>
    );
    if (error) return   (
      <PopupContainer>
        <div className="popup-message">
           Something went wrong
        </div>
        <button className="popup-btn" onClick={onClose}>OK</button>
      </PopupContainer>
    );

    const formattedMessage = message.split('\n').filter(w => !!w).map(w => `<p class="mb-3">${w}</p>`).join('')
  
    return (
      <div className="fixed inset-0 bg-black/30 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 w-full max-w-md mx-4">
            <h3 className="text-lg font-medium mb-4">
              Candidate Sourcing Message
            </h3>
            <div className="border border-gray-200 rounded p-4 mb-3 bg-gray-50">
              <div dangerouslySetInnerHTML={{__html: formattedMessage}}/>
            </div>
            <div className="flex justify-between">
              <button 
                className="px-3 py-1 text-sm border cursor-pointer border-gray-300 rounded text-gray-700 hover:bg-gray-50"
                onClick={onClose}
              >
                Close
              </button>
              <button 
                className="px-3 py-1 text-sm cursor-pointer bg-green-600 text-white rounded hover:bg-green-700"
                onClick={() => onCopy(message)}
              >
                Copy Message
              </button>
            </div>
          </div>
        </div>
    )
  }

  return (
    <div 
      className="fixed inset-0 bg-black/30 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 w-full max-w-md mx-4">
        {renderContent()}
      </div>
    </div>
  )
}

export default ViewMessage;
import React, { useState, useEffect } from 'react';
import { useAuth } from '../hooks';
import PageContainer from '../components/PageContainerV2';
import { useAtom } from 'jotai';
import { userAtom } from "../atoms";
import { readImpersonation, impersonate, clearImpersonation } from "../services";
import DomainSelect from '../components/DomainSelect';


const HonestResumeDashboard = () => {
  const [profileCount, setProfileCount] = useState(10); // Default value
  const [domain, setDomain] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [impersonationEmail, setImpersonationEmail] = useState(null);
  const [email, setEmail] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(true);
  const [user] = useAtom(userAtom);
  useAuth();

  const loadImpersonation = async () => {
    try {
      setLoading(true);
      const res = await readImpersonation();
      if (res.email) {
        setImpersonationEmail(res.email);
        setEmail(res.email);
      } else {
        setImpersonationEmail('');
        setEmail('');
      }
    } catch(err) {
      console.log(err)
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (user) {
      if (['amitvsawant@gmail.com', 'aleksey.shishkevich@gmail.com'].includes(user.email)) {
        setIsAdmin(true);
        loadImpersonation();
      } else {
        setLoading(false);
      }
    }
  }, [user])

  const handleSave = async () => {
    setIsSaving(true);
    // Simulate API call
    await new Promise(resolve => setTimeout(resolve, 1000));
    setIsSaving(false);
  };

  const onImpersonateClick = async () => {
    try {
      setLoading(true);
      await impersonate(email);
      await loadImpersonation();
      setEmail('');
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }

  const onClearImpersonationClick = async () => {
    try {
      setLoading(true);
      await clearImpersonation();
      await loadImpersonation();
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }

  const renderImpersonation = () => {
    if (impersonationEmail) return <>
      <div className="text-gray-700 text-m">
        Impersonating user: <span className='font-medium text-black px-2'>{impersonationEmail}</span>
        <button 
          onClick={ onClearImpersonationClick }
          className="bg-green-600 mt-4 cursor-pointer text-white px-4 py-2 rounded-md hover:bg-green-700 flex items-center">
          Clear Impersonation
        </button>
      </div>
    </>
    return <>
      <div className="flex items-center space-x-4">
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="block w-80 rounded-md border border-gray-300 px-3 py-2 focus:border-green-500 focus:ring-green-500"
        />
        <button 
          onClick={ onImpersonateClick }
          disabled={!email}
          className="bg-green-600 cursor-pointer text-white px-4 py-2 rounded-md hover:bg-green-700 flex items-center">
          Impersonate
        </button>
      </div>
    </>
  }

  const onDomainSelect = (value) => {
    setDomain(value);
  }

  return <PageContainer loading={loading} title='Settings' selected='settings'>
    <div className="max-w-2xl">
        {
          isAdmin &&
          <div className="bg-white rounded-lg border border-gray-200 p-6 mb-6">
              <h3 className="text-lg font-medium text-gray-900 mb-6">Impersonation</h3>
              {renderImpersonation()}
              <div className="space-y-4"></div>
          </div>
        }
            <div className="bg-white rounded-lg border border-gray-200 p-6">
              <h3 className="text-lg font-medium text-gray-900 mb-6">Profile Sourcing Settings</h3>
              
              <div className="space-y-4">
                <div>
                  <label htmlFor="profileCount" className="block text-sm font-medium text-gray-700 mb-2">
                    Daily profile sourcing limit per role
                  </label>
                  <div className="flex items-center space-x-4">
                    <input
                      type="number"
                      id="profileCount"
                      name="profileCount"
                      min="1"
                      max="1000"
                      disabled={true}
                      value={profileCount}
                      onChange={(e) => setProfileCount(Number(e.target.value))}
                      className="block w-32 rounded-md border border-gray-300 px-3 py-2 focus:border-green-500 focus:ring-green-500"
                    />
                    <span className="text-sm text-gray-500">profiles/day</span>
                  </div>
                  <p className="mt-2 text-sm text-gray-500">
                    This limits how many new candidate profiles will be sourced each day for each active role.
                  </p>
                </div>

                <div className="mt-2">
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Domain
                  </label>
                  <div className="w-[20rem]">
                   <DomainSelect onSelect={onDomainSelect} value={domain}/>
                  </div>
                </div>

                <div className="pt-4">
                  <button
                    onClick={handleSave}
                    disabled={true}
                    className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-400 cursor-not-allowed"
                  >
                    {isSaving ? 'Saving...' : 'Save Changes'}
                  </button>
                </div>
              </div>
            </div>
          </div>
  </PageContainer>
};

export default HonestResumeDashboard;
import React from 'react';
import './mainV2.css';
import { Zap, Building2, Clock, TrendingUp, Filter } from 'lucide-react';
import { useNavigate } from 'react-router';
import { useAuth } from "../hooks";
import styled from 'styled-components';
import Footer from '../components/FooterV2';
import NavBar from '../components/NavBarV2';

const Container = styled.div`
 .step-container {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .step {
      width: 100%;
      max-width: 50rem;
      /* line-height: 2rem; */
      margin-bottom: 1rem;
      font-weight: 500;
      .in-circle-wrapper {
        display: inline-block;
        position: relative;
        .in-circle {
          position: absolute;
          top: -1.2rem;
          left: -2rem;
          border: 1.5px solid black;
          display: inline-flex;
          box-sizing: border-box;
          width: 1.5rem;
          height: 1.5rem;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          font-size: 0.9rem;
      }
      }
  }
 }

`

const HonestResumeHomepage = () => {
  const navigate = useNavigate();
  useAuth(false);

  const onTryNowClick = () => {
    navigate('/signup');
  }
  return (
    <Container className="min-h-screen flex flex-col">
      <NavBar/>

      <header 
        className="flex-grow flex items-center justify-center text-center px-4 py-32"
      >
        <div className="max-w-3xl">
          <h1 className="text-5xl font-bold mb-6 text-gray-800">
            Discover Top-Tier Tech Talent
          </h1>
          <p className="text-xl text-gray-600 mb-8">
            AI-powered candidate matching from venture-backed companies with proven career growth
          </p>
          <div className="flex justify-center">
            <button 
              onClick={onTryNowClick}
              className="cursor-pointer bg-green-600 text-white px-6 py-3 rounded-md hover:bg-green-700 flex items-center">
              Start Sourcing <Zap className="ml-2" />
            </button>
          </div>
        </div>
      </header>

      <section id="features" className="bg-gray-50 py-16">
        <div className="max-w-6xl mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">How We Find Exceptional Candidates</h2>
          <div className="grid md:grid-cols-4 gap-8">
            {[
              {
                title: "Venture-Backed Talent",
                description: "Source candidates from high-growth companies with proven track records",
                icon: <Building2 className="w-12 h-12 text-green-600 mx-auto" />
              },
              {
                title: "Career Progression Analysis",
                description: "Evaluate candidates' professional growth, skills, and career trajectory",
                icon: <TrendingUp className="w-12 h-12 text-green-600 mx-auto" />
              },
              {
                title: "Loyalty & Tenure Analysis",
                description: "Assess candidate stability and commitment through detailed employment history",
                icon: <Clock className="w-12 h-12 text-green-600 mx-auto" />
              },
              {
                title: "Advanced Candidate Filtering",
                description: "Comprehensive screening including immigration status, experience, internships, and educational quality",
                icon: <Filter className="w-12 h-12 text-green-600 mx-auto" />
              }
            ].map((feature, index) => (
              <div key={index} className="bg-white p-6 rounded-lg shadow-md text-center">
                <div className="mb-4">{feature.icon}</div>
                <h3 className="text-xl font-semibold mb-3">{feature.title}</h3>
                <p className="feature-description">{feature.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section id="sourcing-steps" className="py-16 bg-gray-50">
        <div className="max-w-3xl mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">Steps for Sourcing</h2>
          <ol className="space-y-8 list-none pl-0">
            <li className="flex justify-center text-center">
              <div className="inline-flex items-center gap-4">
                <span className="w-8 h-8 bg-green-600 text-white rounded-full flex items-center justify-center font-semibold">1</span>
                <span className="text-xl text-gray-700 leading-relaxed">Create a trial account</span>
              </div>
            </li>
            <li className="flex justify-center text-center">
              <div className="inline-flex items-center gap-4">
                <span className="w-8 h-8 bg-green-600 text-white rounded-full flex items-center justify-center font-semibold">2</span>
                <span className="text-xl text-gray-700 leading-relaxed">Create a role you are hiring for and set sourcing conditions</span>
              </div>
            </li>
            <li className="flex justify-center text-center">
              <div className="inline-flex items-center gap-4">
                <span className="w-8 h-8 bg-green-600 text-white rounded-full flex items-center justify-center font-semibold">3</span>
                <span className="text-xl text-gray-700 leading-relaxed">Every day at 6am ET we will source 10 new profiles</span>
              </div>
            </li>
            <li className="flex justify-center text-center">
              <div className="inline-flex items-center gap-4">
                <span className="w-8 h-8 bg-green-600 text-white rounded-full flex items-center justify-center font-semibold">4</span>
                <span className="text-xl text-gray-700 leading-relaxed">Click the profile name to open Linkedin page and send the message</span>
              </div>
            </li>
          </ol>
        </div>
      </section>

      <section className="bg-green-600 text-white py-16 text-center">
        <h2 className="text-4xl font-bold mb-6">Revolutionize Your Hiring Process</h2>
        <p className="text-xl mb-8">
          Reduce hiring costs and find top talent faster with intelligent candidate sourcing
        </p>
        <button 
          onClick={onTryNowClick}
          className="cursor-pointer bg-white text-green-600 px-8 py-4 rounded-md hover:bg-gray-100">
          Get Started
        </button>
      </section>
      <Footer/>      
      
    </Container>
  );
};

export default HonestResumeHomepage;

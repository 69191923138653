import React, { useState, useEffect } from 'react';
import { useAuth } from '../hooks';
import PageContainer from '../components/PageContainerV2';
import StyledModal from '../components/StyledModal';
import { toolsProfileInfo, toolsSetProfileUrl, toolsGetDailyUrlCount } from "../services";
import { Search } from 'lucide-react';

const NOT_FOUND = -1;

function validURL(str) {
  var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
  return !!pattern.test(str);
}

const HonestResumeDashboard = () => {
  const [ loading, setLoading ] = useState(false);
  const [ profileData, setProfileData ] = useState(null);
  const [ profileUrl, setProfileUrl ] = useState('');
  const [ modalMessage, setModalMessage ] = useState(null);
  const [ dailyUrlCount, setDailyUrlCount ] = useState(undefined);

  useAuth();

  const getDailyUrlCount = async () => {
    try {
      const res = await toolsGetDailyUrlCount();
      if (res?.daily_cnt !== undefined) {
        setDailyUrlCount(res.daily_cnt);
      }
    } catch (err) {
      console.log(err);
    }
  } 

  useEffect(() => {
    getDailyUrlCount();
  }, [])

  const onNextProfileClick = async () => {
    setLoading(true);
    setProfileData(null);
    setProfileUrl('');
    try {
      const res = await toolsProfileInfo();
      if (res) {
        setProfileData(res);
      } else {
        setProfileData(NOT_FOUND);
      }
    } catch (err) {
      setProfileData(NOT_FOUND);
    } finally {
      setLoading(false);
    }
  }

  const onInputChange = (e) => {
    setProfileUrl(e.target.value);
  }

  const saveProfileUrl = async () => {
    if (profileData?.profile_id && profileUrl) {
      setLoading(true);
      try {
        await toolsSetProfileUrl(profileData.profile_id, profileUrl);
        await getDailyUrlCount();
        setModalMessage('Saved!');
      } catch (err) {
        setModalMessage('Failed to save!');
      } finally {
        setLoading(false);
      }
    }
  }
  

  const renderRecord = (record) => {
    if (typeof record === 'object' && record !== null) return JSON.stringify(record);
    if (typeof record === 'boolean') return JSON.stringify(record);
    if (validURL(record)) return <a target='__blank' href={record}>{record}</a>
    return record;
  }
  

  const renderProfileData = () => {
    if (profileData === null) return null;
    if (profileData === NOT_FOUND) return  (
      <div className="bg-gray-100 p-4 rounded-lg">
        Not found
      </div>
    );
    return <>
      {
        Object.keys(profileData).map(key => (
          <div key={key}>
            <label className='font-semibold'>{key}: </label>
            <span>{renderRecord(profileData[key])}</span>
          </div>
        ))
      }
      <div className="flex justify-start gap-5 mb-6 mt-6">
          <input
            type="text"
            id="name"
            name="name"
            onChange={onInputChange}
            placeholder='Profile url'
            value={profileUrl}
            className="w-full max-w-[20rem] p-2 border border-gray-300 rounded-md focus:ring-1 focus:ring-green-500 focus:border-green-500"
          />
          <button 
            onClick={ saveProfileUrl }
            disabled={!profileUrl}
            className="bg-green-600 cursor-pointer text-white px-4 py-2 rounded-md hover:bg-green-700 flex items-center">
            Save
          </button>
        </div>
    </>
  }

  const renderUrlCounter = () => {
    if (dailyUrlCount !== undefined) return (
      <div>Todays Count: {dailyUrlCount}</div>
    );
    return null;
  }

  return <>
    <PageContainer tools={true} loading={loading} title='Profile Info' selected='profile_info'>
      <div className="space-y-2 max-w-[60rem]"> 
        <div className="flex justify-between items-center gap-5 mb-18 max-w-[60rem]">
          <button 
            onClick={ onNextProfileClick }
            className="bg-green-600 cursor-pointer text-white px-4 py-2 rounded-md hover:bg-green-700 flex items-center">
            <Search className="mr-2" size={20} />
            Next Profile
          </button>
          {renderUrlCounter()}
        </div>
        {renderProfileData()}
      </div>
    </PageContainer>
    <StyledModal 
      isOpen={!!modalMessage} 
      modalStyle={{minWidth: 'auto', width: 'auto'}} 
      onClose={() => setModalMessage('')}
      hideCrossButton
    >
        <div className='flex flex-col items-center justify-center'>
          <div className='px-16 py-6'>
          {modalMessage}
          </div>
          <button 
            onClick={ () => setModalMessage(null) }
            className="bg-green-600 cursor-pointer text-white px-8 py-2 rounded-md hover:bg-green-700 flex items-center">
            OK
          </button>
        </div>
    </StyledModal>
  </>

};

export default HonestResumeDashboard;
import React from 'react';
import { Check, Zap, Star, Shield } from 'lucide-react';
import NavBar from '../components/NavBarV2';
import Footer from '../components/FooterV2';

const PricingPage = () => {
  const tiers = [
    {
      name: "Starter",
      price: "149",
      description: "Perfect for small teams and startups",
      features: [
        "Source 10 high quality profiles a day for 1 open role",
        "Phone + Email Support"
      ],
      icon: <Zap className="w-6 h-6" />,
      isPopular: false
    },
    {
      name: "Professional",
      price: "499",
      description: "Ideal for growing companies",
      features: [
        "Source 10 high quality profiles a day for 5 open roles",
        "Phone + Email Support"
      ],
      icon: <Star className="w-6 h-6" />,
      isPopular: true
    },
    {
      name: "Enterprise",
      price: "Custom",
      description: "For large organizations",
      features: [
        "Sourcing for 5+ open roles",
        "Dedicated account manager",
        "Phone + Email priority support"
      ],
      icon: <Shield className="w-6 h-6" />,
      isPopular: false
    }
  ];

  return (
    <div className="min-h-screen flex flex-col">
      <NavBar hideLogin/>

      <main className="flex-grow">
        <div className="max-w-6xl mx-auto px-4 py-16">
          <div className="text-center mb-16">
            <h1 className="text-4xl font-bold mb-4 text-gray-800">
              Simple, Transparent Pricing
            </h1>
            <p className="text-xl text-gray-600">
              Choose the plan that best fits your hiring needs
            </p>
          </div>

          <div className="grid md:grid-cols-3 gap-8">
            {tiers.map((tier, index) => (
              <div
                key={index}
                className={`relative bg-white rounded-lg shadow-lg p-8 ${
                  tier.isPopular ? 'border-2 border-green-600' : ''
                }`}
              >
                {tier.isPopular && (
                  <div className="absolute -top-4 left-1/2 transform -translate-x-1/2">
                    <span className="bg-green-600 text-white px-4 py-1 rounded-full text-sm">
                      Most Popular
                    </span>
                  </div>
                )}
                <div className="flex items-center justify-center mb-4">
                  <div className="p-3 bg-green-100 rounded-full">
                    {React.cloneElement(tier.icon, { className: "text-green-600" })}
                  </div>
                </div>
                <h3 className="text-2xl font-bold text-center mb-2">{tier.name}</h3>
                <div className="text-center mb-4">
                  <span className="text-4xl font-bold">${tier.price}</span>
                  {tier.price !== "Custom" && <span className="text-gray-600">/month</span>}
                </div>
                <p className="text-gray-600 text-center mb-6">{tier.description}</p>
        
                <ul className="space-y-4">
                  {tier.features.map((feature, featureIndex) => (
                    <li key={featureIndex} className="flex items-center">
                      <Check className="w-5 h-5 text-green-600 mr-2 flex-shrink-0" />
                      <span className="text-gray-600">{feature}</span>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>

        <section className="bg-gray-50 py-16">
          <div className="max-w-4xl mx-auto px-4 text-center">
            <h2 className="text-3xl font-bold mb-6">Frequently Asked Questions</h2>
            <div className="grid gap-6 text-left">
              {[
                {
                  q: "Can I change plans at any time?",
                  a: "Yes, you can upgrade or downgrade your plan at any time. Changes will be reflected in your next billing cycle."
                },
                {
                  q: "What payment methods do you accept?",
                  a: "We accept all major credit cards, ACH payments, and wire transfers for enterprise customers."
                },
                {
                  q: "Is there a contract or commitment?",
                  a: "No, our plans are month-to-month with no long-term commitment required. Enterprise plans may have custom terms."
                }
              ].map((faq, index) => (
                <div key={index} className="bg-white p-6 rounded-lg shadow-sm">
                  <h3 className="font-semibold text-lg mb-2">{faq.q}</h3>
                  <p className="text-gray-600">{faq.a}</p>
                </div>
              ))}
            </div>
          </div>
        </section>
      </main>

      <Footer/>
    </div>
  );
};

export default PricingPage;
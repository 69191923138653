import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { PlusCircle } from 'lucide-react';
import "./sourcingRolesV2.css";
import { useAuth } from '../hooks';
import { 
  getSourcedRoles,
  setSourcedRoleStatus,
  addSourcedRole,
  updateSourcedRole,
  apiReplayer,
  getSourcedRoleDetails
} from "../services";
import SourcingRoleModalV2 from '../components/SourcingRoleModalV2';
import SourcedRoleListItemV2 from "../components/SourcedRoleListItemV2";
import PageContainer from '../components/PageContainerV2';


const MessagePopupContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 200;
  display: flex;
  justify-content: center;
  align-items: center;
  .popup {
    background-color: white;
    padding: 25px;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    max-width: 450px;
    text-align: center;
    .popup-message {
      margin-bottom: 20px;
      font-size: 16px;
      line-height: 1.5;
    }
    .popup-btn {
      padding: 10px 25px;
      background-color: #4CAF50;
      color: white;
      border: none;
      border-radius: 4px;
      font-size: 16px;
      cursor: pointer;
    }
    .popup-btn:hover {
      background-color: #45a049;
    }
  }
`

const HonestResumeDashboard = () => {
  const [ roles, setRoles ] = useState([]);
  const [ selectedRole, setSelectedRole ] = useState(null);
  const [ loading, setLoading ] = useState(true);
  const isLoading = useRef(false);
  const [ isRoleModalOpen, setRoleModalOpen ] = useState(false);
  const [ loadingRoleDetails, setLoadingRoleDetails ] = useState(false);
  const [ message, setMessage ] = useState(false);
  useAuth();

  useEffect(() => {
    if (!isLoading.current) {
      fetchData();
    }
    return () => {
      setRoles([]);
    }
  }, []);

  const fetchData = async () => {
    setLoading(true);
    isLoading.current = true;
    const dataRoles = await apiReplayer(getSourcedRoles, (res) => !!res.roles);
    if (dataRoles?.roles) {
      setRoles(dataRoles?.roles);
    } else {
      setRoles([]);
    }
    setLoading(false);
    isLoading.current = false;
  }

  const addRoleClick = () => {
    setSelectedRole(null);
    setRoleModalOpen(true);
  }

  const onRoleSave = async (data) => {
    setRoleModalOpen(false);
    setLoading(true);
    isLoading.current = true;
    if (selectedRole) {
      await updateSourcedRole(data, selectedRole?.role_id);
    } else {
      await addSourcedRole(data);
    }
    await fetchData();
    setMessage('Sourcing conditions have been updated for this open role. The changes will be applied on the next batch of profiles, at 6am ET.')
    setSelectedRole(null);
  }
  
  const onRoleModalClose = () => {
    setRoleModalOpen(false);
  }

  const onFilterOpen = async (data) => {
    setLoadingRoleDetails(true);
    setRoleModalOpen(true);
    try {
      const res = await getSourcedRoleDetails(data?.role_id);
      if (res) {
        setSelectedRole({role_id: data?.role_id, ...res});
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingRoleDetails(false);
    }
  }

  const onRoleStatusChange = async (role_id, status) => {
    await setSourcedRoleStatus(role_id, status);
  }


  const renderContent = () => {
    if (loading) return null;

    if (roles.length === 0) return  (<>
      <div className="flex justify-end mb-4 max-w-[53rem]">
        <button 
          onClick={ addRoleClick }
          className="bg-green-600 cursor-pointer text-white px-4 py-2 rounded-md hover:bg-green-700 flex items-center w-36">
          <PlusCircle className="mr-2" size={20} />
          Add Role
        </button>
      </div>
      <div className="bg-gray-100 p-4 rounded-lg max-w-[53rem]">
        No roles have been added yet. Click "Add Role" to get started.<br/>
        A role is an open position at your company.<br/>
        For each role please provide sourcing conditions. We use these conditions to filter candidate profiles.
      </div>
    </>);

    return <>
      <div className="flex justify-end mb-4 max-w-[60rem]">
        <button 
          onClick={ addRoleClick }
          className="bg-green-600 cursor-pointer text-white px-4 py-2 rounded-md hover:bg-green-700 flex items-center w-36">
          <PlusCircle className="mr-2" size={20} />
          Add Role
        </button>
      </div>
      <div className="space-y-4 max-w-[60rem]">
        {
          roles.map((item, i) => (
            <SourcedRoleListItemV2 
              key={i} 
              data={item} 
              onChange={onRoleStatusChange}
              onFilterOpen={() => onFilterOpen(item)}
            />
          ))
        }
      </div>
    </>;  
  };

  return (<>
    <PageContainer loading={loading} title='Roles' selected='roles'>
      {renderContent()}
    </PageContainer>
    <SourcingRoleModalV2 
      isOpen={ isRoleModalOpen }
      data={ selectedRole }
      onSubmit={ onRoleSave }
      onClose={ onRoleModalClose }
      loading={loadingRoleDetails}
    />
     {message && (
        <MessagePopupContainer>
          <div className="popup">
            <div className="popup-message">
              {message}
            </div>
            <button className="popup-btn" onClick={() => setMessage(null)}>OK</button>
          </div>
        </MessagePopupContainer>
      )}
  </>);
};

export default HonestResumeDashboard;

import React, { Component, useState, useEffect } from 'react';
import "./SourcingRoleModalV2.css";
import styled from 'styled-components';
import Select, { createFilter } from 'react-select';
import { FixedSizeList as List } from "react-window";
import { useAtom } from 'jotai';
import { 
  titlesAtom, 
  citiesAtom,
  statesAtom
} from "../atoms";
import LoadingScreen from './LoadingScreen';
import { X, ChevronRight } from 'lucide-react';
import CheckBox from './CheckBoxV2';


const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 30rem;
  min-width: 30rem;
  .no-wrap {
    text-wrap-mode: nowrap;
  }

  .flex-wrap {
    flex-flow: wrap;
  }

  .skip_tenure-select-container {
    margin-top: -0.25rem;
    display: inline;
    padding: 0 0.5rem;
  }

  .us_resident-select-container {
    margin-top: -0.25rem;
    display: inline;
    padding: 0 0.5rem;
  }

  .close-button {
    position: absolute;
    top: 50%;
    left: 1rem;
    transform: translateY(-50%);
    cursor: pointer;
  }

  .input-container {
    display: flex;
    gap: 0.5rem;
    input[type="number"] {
      max-width: 7.5rem;
    }
    input[type="number"]::-webkit-inner-spin-button {
      opacity: 1 !important;
    }
  }

  .skip_tenure-select {
    width: 3.5rem;
    .skip_tenure__menu {
      margin-top: 0 !important;
      padding: 0 !important;
      .skip_tenure__option {
          font-size: 0.9rem !important;
        }
      .skip_tenure__menu-list {
        padding: 0 !important;

      }
    }
    .skip_tenure__control {
      min-height: 1px !important;
    }
    .skip_tenure__value-container {
      padding: 0 0 0 0.5rem  !important;
    }
    .skip_tenure__indicators {
      .skip_tenure__dropdown-indicator {
        padding: 0 2px;
      }
      .skip_tenure__indicator-separator {
        display: none;
      }
    }
  }

  .us_resident-select {
    min-width: 4.2rem;
    .us_resident__menu {
      margin-top: 0 !important;
      padding: 0 !important;
      .us_resident__option {
          font-size: 0.9rem !important;
        }
      .us_resident__menu-list {
        padding: 0 !important;

      }
    }
    .us_resident__control {
      min-height: 1px !important;
    }
    .us_resident__value-container {
      padding: 0 0 0 0.5rem  !important;
    }
    .us_resident__indicators {
      .us_resident__dropdown-indicator {
        padding: 0 2px;
      }
      .us_resident__indicator-separator {
        display: none;
      }
    }
  }


  .location-type-select {
    .location__indicators {
      .location__indicator-separator {
        display: none;
      }
    }
    .location__control {
      padding: calc(var(--spacing)* 0.5) calc(var(--spacing)* 2);
      background-color: var(--color-white);
      border-color: var(--color-gray-300);
      border-style: var(--tw-border-style);
      border-width: 1px;
      border-radius: var(--radius-md);
      width: 100%;
      --tw-ease: var(--ease-in-out);
      .location__value-container {
        padding: 0;
        /* min-height: 3.5rem; */
        .location__input {
          min-height: auto !important;
        }
        .location__multi-value {
          background: white;
          border: 1px solid black;
          >div {
            background: white;
            svg {
              opacity: 0.6;
            }
          }
        }
      }
      .location__multi-value__remove:hover {
        background-color: #ccc;
        color: black;
      }
      .location__placeholder {
        color:  var(--color-gray1);
        font-size: 1rem;
        padding: 0;
      }
    }
    .location__menu {
      margin-top: -1px !important;
      padding: 0 !important;
      .location__option {
          font-size: 0.9rem !important;
        }
      .location__menu-list {
        padding: 0 !important;

      }
    }
  }
  .sourcing_title-select {
    width: 100%;
    .sourcing_title__control--is-disabled {
      background: transparent !important;
      .sourcing_title__indicators {
        display: none !important;
      }
    }
    .sourcing_title__control {

      box-shadow: none;
      /* height: 3.4rem; */
      color: black;
      /* font-weight: 400;
      font-family: Roboto;
      padding-left: 0; */


      padding: calc(var(--spacing)* 0.5) calc(var(--spacing)* 2);
      background-color: var(--color-white);
      border-color: var(--color-gray-300);
      border-style: var(--tw-border-style);
      border-width: 1px;
      border-radius: var(--radius-md);
      width: 100%;
      --tw-ease: var(--ease-in-out);


      .sourcing_title__value-container {
        padding: 2px 0;
      }
      .sourcing_title__placeholder {
        color: var(--color-gray1);
        font-size: 1em;
      }
      .sourcing_title__single-value {
        color: black; 
        /* font-weight: 400 !important;
        font-family: Roboto; */
        font-size: 1em;
      }
      .sourcing_title__indicators {
        .sourcing_title__indicator-separator {
          display: none;
        }
        .sourcing_title__dropdown-indicator {
          color: black !important;
          font-weight: 400 !important;
          font-family: Roboto;
          opacity: 0.5;
          padding: 0.5rem 0;
        }
      }
    }
    .sourcing_title__menu {
      margin-top: 0 !important;
      padding: 0 !important;
      .sourcing_title__menu-list {
        padding: 0 !important;
        .sourcing_title__option {
          color: black;
          height: 2rem;
          /* font-weight: 400 !important;
          font-family: Roboto; */
          display: flex;
          align-items: center;
          padding-left: 0.5rem;
          padding: 0.5rem;
          font-size: 1em;
          &.sourcing_title__option--is-selected {
            color: white;
          }
          &.sourcing_title__option--is-focused {
            
          }
        }
      }
    }
  }
  .error-message {
    font-size: 0.8rem;
    color: var(--color-red-1);
    margin-top: 0.5rem;
  }
  .control-container {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex: 1;
    button {
      width: 100%;
      margin: calc(var(--spacing)* 6);
    }
  }
  .loading-screen {
    background: white !important;
  }
`

const HEIGHT = 35;
class MenuList extends Component {
  render() {
    const { options, children, maxHeight, getValue } = this.props;
    const [value] = getValue();
    const initialOffset = options.indexOf(value) * HEIGHT;

    return (
      <List
        height={maxHeight}
        itemCount={children.length}
        itemSize={HEIGHT}
        initialScrollOffset={initialOffset}
      >
        {({ index, style }) => <div style={style}>{children[index]}</div>}
      </List>
    );
  }
}

const SKIP_TENURE_VALUES = [
  {value: '5', label: '5'},
  {value: '8', label: '8'},
  {value: '10', label: '10'},
];

function getSkipTenureValue(value) {
  const selected = SKIP_TENURE_VALUES?.find(item => item.value === value);
  return selected || SKIP_TENURE_VALUES[0];
}

const US_RESIDENT_VALUES = [
  {value: 'Any', label: 'Any'},
  {value: '5+', label: '5+'},
  {value: '10+', label: '10+'},
  {value: '15+', label: '15+'},
];

function getUSResidentValue(value) {
  const selected = US_RESIDENT_VALUES?.find(item => item.value === value);
  return selected || US_RESIDENT_VALUES[0];
}


// Sliding Pane Component
const SlidingPane = ({ isOpen, loading, onClose, onSubmit, data }) => {
  const [activeTab, setActiveTab] = useState('general');
  const [formData, setFormData] = useState();
  const [titles] = useAtom(titlesAtom);
  const [states] = useAtom(statesAtom);
  const [cities] = useAtom(citiesAtom);
  const [filteredStates, setFilteredStates] = useState([]);
  const [filteredCities, setFilteredCities] = useState([]);
  const [selectedTitle, setSelectedTitle] = useState();
  const [showWhoExplanation, setShowWhoExplanation] = useState(false);
  const [skipTenureValue, setSkipTenureValue] = useState(getSkipTenureValue(data?.skip_tenure_at_latest));
  const [usResidentValue, setUsResidentValue] = useState(getUSResidentValue(data?.us_resident_years));

  useEffect(() => {
    if (states) {
      setFilteredStates([...states]);
    }
  }, [states])

  useEffect(() => {
    if (cities) {
      setFilteredCities([...cities]);
    }
  }, [cities])

  useEffect(() => {
    if (isOpen) {
      setActiveTab('general');
      clearFormData(data);
    }
  }, [data, isOpen])

  useEffect(() => { 
    if (titles && data) {
      const title = titles?.find(item => item.value === data?.title_id);
      if (title && !selectedTitle) {
        setSelectedTitle(title);
      }
    }
  }, [titles, data, selectedTitle, setSelectedTitle])

  const validateFormData = (data) => {
    const validatedData = {...data};
    let isValid = true;
    if (validatedData['title_id']?.value === '') {
      validatedData['title_id'].error = 'This field cannot be empty.';
      isValid = false;
    }
    if (validatedData['name']?.value === '') {
      validatedData['name'].error = 'This field cannot be empty.';
      isValid = false;
    }
    setFormData(validatedData);
    return isValid;
  } 

  const clearFormData = (data) => {
    setFormData({
      title_id: { value: data?.title_id || '', error: false },
      name: { value: data?.name || '', error: false },
      custom: { value: data?.custom || '', error: false },
      state_ids: { value: data?.state_ids || [], error: false },
      city_ids: { value: data?.city_ids || [], error: false },
      ic_min_in_years: { value: data?.ic_min_in_years || '', error: false },
      ic_max_in_years: { value: data?.ic_max_in_years || '', error: false },
      ml_min_in_years: { value: data?.ic_min_in_years || '', error: false },
      ml_max_in_years: { value: data?.ic_max_in_years || '', error: false },
      principal_min_in_years: { value: data?.principal_min_in_years || '', error: false },
      principal_max_in_years: { value: data?.principal_max_in_years || '', error: false },
      qa_min_in_years: { value: data?.qa_min_in_years || '', error: false },
      qa_max_in_years: { value: data?.qa_max_in_years || '', error: false },
      staff_min_in_years: { value: data?.staff_min_in_years || '', error: false },
      staff_max_in_years: { value: data?.staff_max_in_years || '', error: false },
      devops_min_in_years: { value: data?.devops_min_in_years || '', error: false },
      devops_max_in_years: { value: data?.devops_max_in_years || '', error: false },
      manager_min_in_years: { value: data?.manager_min_in_years || '', error: false },
      manager_max_in_years: { value: data?.manager_max_in_years || '', error: false },
      ae_min_in_years: { value: data?.ae_min_in_years || '', error: false },
      ae_max_in_years: { value: data?.ae_max_in_years || '', error: false },
      skip_if_started_job_1y_ago: { value: data?.skip_if_started_job_1y_ago || false, error: false },
      skip_tenure_at_latest: { value: data?.skip_tenure_at_latest || '', error: false },
      us_bachelors_only: { value: data?.us_bachelors_only || false, error: false },
      us_resident_years: { value: data?.us_resident_years || '', error: false },
      skip_company_names: { value: data?.skip_company_names || [], error: false },
      skip_bootcamp: { value: data?.skip_bootcamp || false, error: false },
    });
    setSkipTenureValue(getSkipTenureValue(data?.skip_tenure_at_latest));
    setUsResidentValue(getUSResidentValue(data?.us_resident_years));
  }
 
  const onInputChange = (e) => {
    const data = {...formData};
    const name  = e.target.name;
    const value = e.target.value;
    data[name].value = value;
    if (name === 'name') {
      data[name].error = false;
    }
    if (e.target.type === 'number' && value.length > 2) {
      return;
    }
    setFormData(data);
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      const name = event.target.value;
      if (formData?.skip_company_names?.value && !formData?.skip_company_names.value.includes(name)) {
        const data = {...formData};
        data.skip_company_names.value.push(name);
        event.target.value = '';
        setFormData(data);
      }
    }
  }

  const onCheckBoxChange = (e) => {
    const data = {...formData};
    const name = e?.target?.name;
    data[name].value = e?.target?.checked;
    if (name === 'skip_tenure_at_latest' && data[name].value) {
      data[name].value = skipTenureValue?.value ;
    }
    if (name === 'us_resident_years' && data[name].value) {
      data[name].value = usResidentValue?.value ;
    }
    setFormData(data);
  }

  const onSkipTenureSelect = (selected) => {
    setSkipTenureValue(selected);
    if (!!formData?.skip_tenure_at_latest?.value) {
      formData.skip_tenure_at_latest.value = selected.value;
    }
  }

  const onUsResidentSelect = (selected) => {
    setUsResidentValue(selected);
    if (!!formData?.us_resident_years?.value) {
      formData.us_resident_years.value = selected.value;
    }
  }

  const onSourcingTitleSelect = (item) => {
    setSelectedTitle(item);
    const data = {...formData};
    data['title_id'].value = item.value;
    data['title_id'].error = false;
    setFormData(data);
  }

  const onLocationChange = (item) => {
    const data = {...formData};
    const ids = data['state_ids'].value;
    ids.push(item.value);
    const fs = filteredStates.filter(s => s !== item);
    setFilteredStates(fs);
    data['state_ids'].error = false;
    setFormData(data);
  }

  const onCityChange = (item) => {
    const data = {...formData};
    const ids = data['city_ids'].value;
    ids.push(item.value);
    const fc = filteredCities.filter(s => s !== item);
    setFilteredCities(fc);
    data['city_ids'].error = false;
    setFormData(data);
  }

  const onSubmitClick = async () => {
    if (validateFormData(formData)) {
      //setUploading(true);
      const data = {};
      for (let key in formData) {
        data[key] = formData[key]?.value;
      }
      await onSubmit(data);
      clearFormData();
      //setUploading(false);  
    } else {
      setTimeout(() => {
        const errorInput = document.querySelector('div.error-red-message')
        if (errorInput) {
          errorInput.scrollIntoView();
        }
      }, 50)
    }
  }

  const removeState = (state_id) => {
    const data = {...formData};
    const ids  = data['state_ids'].value?.filter(id => id !== state_id);
    const fs = states.filter(item => !ids.includes(item.value));
    setFilteredStates(fs);
    data['state_ids'].error = false;
    data['state_ids'].value = ids;
    setFormData(data);
  }

  const removeCity = (city_id) => {
    const data = {...formData};
    data['city_ids'].value  = data['city_ids'].value?.filter(id => id !== city_id);
    data['city_ids'].error = false;
    setFormData(data);
  }

  const removeCompany = (name) => {
    const data = {...formData};
    data['skip_company_names'].value  = data['skip_company_names'].value?.filter(id => id !== name);
    data['skip_company_names'].error = false;
    setFormData(data);
  }

  const getStateById = (id) => {
    return states.find(item => item.value === id)
  }

  const getCityById = (id) => {
    return cities.find(item => item.value === id)
  }

  const renderExperience = () => {
    const title = selectedTitle?.label;

    if (title === 'Account Executive') return <>
      <div>
        <label htmlFor="ae_min_in_years" className="block text-sm font-medium text-gray-700 mb-1">Account Executive Experience</label>
        <div className='input-container'>
          <input 
            placeholder="Min years"
            name="ae_min_in_years"
            type="number"
            min={0}
            onChange={onInputChange}
            value={formData?.ae_min_in_years.value || ''}
            className="w-full p-2 border border-gray-300 rounded-md focus:ring-1 focus:ring-green-500 focus:border-green-500"
          />
          <input 
            placeholder="Max years"
            name="ae_max_in_years"
            type="number"
            min={0}
            onChange={onInputChange}
            value={formData?.ae_max_in_years.value || ''}
            className="w-full p-2 border border-gray-300 rounded-md focus:ring-1 focus:ring-green-500 focus:border-green-500"
          />
        </div>
      </div>
    </>

    if (title === 'Engineering Manager') return <>
      <div>
        <label htmlFor="manager_min_in_years" className="block text-sm font-medium text-gray-700 mb-1">Engineering Manager Experience</label>
        <div className='input-container'>
          <input 
            placeholder="Min years"
            name="manager_min_in_years"
            type="number"
            min={0}
            onChange={onInputChange}
            value={formData?.manager_min_in_years.value || ''}
            className="w-full p-2 border border-gray-300 rounded-md focus:ring-1 focus:ring-green-500 focus:border-green-500"
          />
          <input 
            placeholder="Max years"
            name="manager_max_in_years"
            type="number"
            min={0}
            onChange={onInputChange}
            value={formData?.manager_max_in_years.value || ''}
            className="w-full p-2 border border-gray-300 rounded-md focus:ring-1 focus:ring-green-500 focus:border-green-500"
          />
        </div>
      </div>
      <div>
        <label htmlFor="ic_min_in_years" className="block text-sm font-medium text-gray-700 mb-1">Software Engineer Experience</label>
        <div className='input-container'>
          <input 
            placeholder="Min years"
            name="ic_min_in_years"
            type="number"
            min={0}
            onChange={onInputChange}
            value={formData?.ic_min_in_years.value || ''}
            className="w-full p-2 border border-gray-300 rounded-md focus:ring-1 focus:ring-green-500 focus:border-green-500"
          />
          <input 
            placeholder="Max years"
            name="ic_max_in_years"
            type="number"
            min={0}
            onChange={onInputChange}
            value={formData?.ic_max_in_years.value || ''}
            className="w-full p-2 border border-gray-300 rounded-md focus:ring-1 focus:ring-green-500 focus:border-green-500"
          />
        </div>
      </div>
    </>

    if (title === 'Staff Software Engineer') return <>
      <div>
        <label htmlFor="ic_min_in_years" className="block text-sm font-medium text-gray-700 mb-1">
          Software Engineer Experience
        </label>
        <div className='input-container'>
          <input 
            placeholder="Min years"
            name="ic_min_in_years"
            type="number"
            min={0}
            onChange={onInputChange}
            value={formData?.ic_min_in_years.value || ''}
            className="w-full p-2 border border-gray-300 rounded-md focus:ring-1 focus:ring-green-500 focus:border-green-500"
          />
          <input 
            placeholder="Max years"
            name="ic_max_in_years"
            type="number"
            min={0}
            onChange={onInputChange}
            value={formData?.ic_max_in_years.value || ''}
            className="w-full p-2 border border-gray-300 rounded-md focus:ring-1 focus:ring-green-500 focus:border-green-500"
          />
        </div>
      </div>
      <div>
        <label htmlFor="staff_min_in_years" className="block text-sm font-medium text-gray-700 mb-1">Staff Software Engineer Experience</label>
        <div className='input-container'>
          <input 
            placeholder="Min years"
            name="staff_min_in_years"
            type="number"
            min={0}
            onChange={onInputChange}
            value={formData?.staff_min_in_years.value || ''}
            className="w-full p-2 border border-gray-300 rounded-md focus:ring-1 focus:ring-green-500 focus:border-green-500"
          />
          <input 
            placeholder="Max years"
            name="staff_max_in_years"
            type="number"
            min={0}
            onChange={onInputChange}
            value={formData?.staff_max_in_years.value || ''}
            className="w-full p-2 border border-gray-300 rounded-md focus:ring-1 focus:ring-green-500 focus:border-green-500"
          />
        </div>
      </div>
    </>

    if (title === 'Software Engineer') return <>
      <div>
        <label htmlFor="ic_min_in_years" className="block text-sm font-medium text-gray-700 mb-1">Software Engineer Experience</label>
        <div className='input-container'>
          <input 
            placeholder="Min years"
            name="ic_min_in_years"
            type="number"
            min={0}
            onChange={onInputChange}
            value={formData?.ic_min_in_years.value || ''}
            className="w-full p-2 border border-gray-300 rounded-md focus:ring-1 focus:ring-green-500 focus:border-green-500"
          />
          <input 
            placeholder="Max years"
            name="ic_max_in_years"
            type="number"
            min={0}
            onChange={onInputChange}
            value={formData?.ic_max_in_years.value || ''}
            className="w-full p-2 border border-gray-300 rounded-md focus:ring-1 focus:ring-green-500 focus:border-green-500"
          />
        </div>
      </div>
    </>
        if (title === 'ML Engineer') return <>
        <div>
          <label htmlFor="ml_min_in_years" className="block text-sm font-medium text-gray-700 mb-1">ML Engineer Experience</label>
          <div className='input-container'>
            <input 
              placeholder="Min years"
              name="ml_min_in_years"
              type="number"
              min={0}
              onChange={onInputChange}
              value={formData?.ml_min_in_years.value || ''}
              className="w-full p-2 border border-gray-300 rounded-md focus:ring-1 focus:ring-green-500 focus:border-green-500"
            />
            <input 
              placeholder="Max years"
              name="ml_max_in_years"
              type="number"
              min={0}
              onChange={onInputChange}
              value={formData?.ml_max_in_years.value || ''}
              className="w-full p-2 border border-gray-300 rounded-md focus:ring-1 focus:ring-green-500 focus:border-green-500"
            />
          </div>
        </div>
      </>
    return null;
  }
  const handleWhoClick = (e) => {
    e.preventDefault();
    setShowWhoExplanation(true);
  };

  const renderContent = () => {
    if (loading) return <LoadingScreen loading={true} color="var(--color-green-700)" secondaryColor="rgba(51,128,63,0.4)"/>;
    // const isExperienceTabVisible =  MAIN_TITLES.includes(selectedTitle?.label);
    if (showWhoExplanation && selectedTitle.description) {
      return (<>
        <div className="flex relative items-center justify-center px-6 py-4 border-b">
          <ChevronRight onClick={onClose} className='close-button' strokeWidth={1.5} />
          <h2 className="text-xl font-semibold text-gray-800">Sourcing Conditions</h2>
        </div>

        <div className="space-y-4 p-6">
          <div className="text-gray-700" dangerouslySetInnerHTML={{__html: selectedTitle.description}}/>
          <button 
            onClick={() => setShowWhoExplanation(false)}
            className="text-blue-600 hover:text-blue-800 mt-4 flex items-center cursor-pointer"
          >
            <span className="mr-1">←</span> Back to form
          </button>
        </div>
      </>);
    }
    return <>
            {/* Header */}
            <div className="flex relative items-center justify-center px-6 py-4 border-b">
              <ChevronRight onClick={onClose} className='close-button' strokeWidth={1.5} />
              <h2 className="text-xl font-semibold text-gray-800">Sourcing Conditions</h2>
            </div>

        {/* Tabs */}
        <div className="border-b">
          <div className="flex">
            <button 
              onClick={() => setActiveTab('general')}
              className={`px-6 py-3 text-sm font-medium border-b-2 transition-colors cursor-pointer ${
                activeTab === 'general' 
                  ? 'text-gray-800 border-green-600' 
                  : 'text-gray-500 border-transparent hover:text-gray-700 hover:border-gray-300'
              }`}
            >
              General
            </button>
            <button 
              onClick={() => setActiveTab('location')}
              className={`px-6 py-3 text-sm font-medium border-b-2 transition-colors cursor-pointer ${
                activeTab === 'location' 
                  ? 'text-gray-800 border-green-600' 
                  : 'text-gray-500 border-transparent hover:text-gray-700 hover:border-gray-300'
              }`}
            >
              Location
            </button>
            <button 
                onClick={() => setActiveTab('experience')}
                className={`px-6 py-3 text-sm font-medium border-b-2 transition-colors cursor-pointer ${
                  activeTab === 'experience' 
                    ? 'text-gray-800 border-green-600' 
                    : 'text-gray-500 border-transparent hover:text-gray-700 hover:border-gray-300'
                }`}
              >
                Experience
              </button>

            <button 
              onClick={() => setActiveTab('custom')}
              className={`px-6 py-3 text-sm font-medium border-b-2 transition-colors cursor-pointer ${
                activeTab === 'custom' 
                  ? 'text-gray-800 border-green-600' 
                  : 'text-gray-500 border-transparent hover:text-gray-700 hover:border-gray-300'
              }`}
            >
              Custom
            </button>
          </div>
        </div>

        <div className="border-b">
          <div className="flex">
            <button 
              onClick={() => setActiveTab('booleans')}
              className={`px-6 py-3 text-sm font-medium border-b-2 transition-colors cursor-pointer ${
                activeTab === 'booleans' 
                  ? 'text-gray-800 border-green-600' 
                  : 'text-gray-500 border-transparent hover:text-gray-700 hover:border-gray-300'
              }`}
            >
              Booleans
            </button>
            <button 
              onClick={() => setActiveTab('lists')}
              className={`px-6 py-3 text-sm font-medium border-b-2 transition-colors cursor-pointer ${
                activeTab === 'lists' 
                  ? 'text-gray-800 border-green-600' 
                  : 'text-gray-500 border-transparent hover:text-gray-700 hover:border-gray-300'
              }`}
            >
              Lists
            </button>
            <button 
                onClick={() => setActiveTab('immigration')}
                className={`px-6 py-3 text-sm font-medium border-b-2 transition-colors cursor-pointer ${
                  activeTab === 'immigration' 
                    ? 'text-gray-800 border-green-600' 
                    : 'text-gray-500 border-transparent hover:text-gray-700 hover:border-gray-300'
                }`}
              >
                Immigration
              </button>
          </div>
        </div>


        {/* Tab Content */}
        <div className="p-6">
          {activeTab === 'general' && (
            <div className="space-y-4">
              <div>
                <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-1">
                  Name (for own reference)
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  onChange={onInputChange}
                  value={formData?.name?.value || ''}
                  className="w-full p-2 border border-gray-300 rounded-md focus:ring-1 focus:ring-green-500 focus:border-green-500"
                />
                {
                  formData?.name?.error &&
                  <div className="error-message">{formData?.name?.error}</div>
                }
              </div>
              <div>

              {/* <label htmlFor="role" className="block text-sm font-medium text-gray-700 mb-1">
                Role
              </label> */}
              <div className="flex items-center justify-between mb-1">
                <label htmlFor="role" className="block text-sm font-medium text-gray-700 mb-1">
                  Role
                </label>
                {
                  selectedTitle?.description &&
                  <button 
                    onClick={handleWhoClick}
                    className="text-sm text-blue-600 hover:text-blue-800 cursor-pointer"
                  >
                    Who?
                  </button>
                }
                </div>
                <Select
                    className="sourcing_title-select"
                    classNamePrefix="sourcing_title" 
                    placeholder="Select a role" 
                    options={titles || []}
                    value={selectedTitle}
                    onChange={onSourcingTitleSelect}
                    isDisabled={!!data}
                    isSearchable={true}
                    maxMenuHeight={'14rem'}
                  />
              {
                formData?.title_id?.error &&
                <div className="error-message">{formData?.title_id?.error}</div>
              }
              </div>
            </div>
          )}
          {activeTab === 'location' && (
            <div className="space-y-4">
              <p className="text-sm text-gray-800">
                Please select one or many US states and cities you want us to source profiles from.
              </p>
              <div className="relative">
                <label htmlFor="state" className="block text-sm font-medium text-gray-700 mb-1">
                  US State
                </label>   
                <Select
                    isClearable={true}
                    className="location-type-select"
                    classNamePrefix="location" 
                    closeMenuOnSelect={true}
                    value=""
                    placeholder="Search for a state..." 
                    onChange={onLocationChange}
                    maxMenuHeight={'14rem'}
                    options={filteredStates}
                  />

                <div className="flex flex-wrap gap-2 mt-2">
                  {formData.state_ids.value.map((state_id) => (
                    <div
                      key={state_id}
                      className="flex items-center gap-1 px-3 py-1 bg-gray-100 rounded-full text-sm text-gray-700"
                    >
                      {getStateById(state_id)?.label}
                      <button
                        onClick={() => removeState(state_id)}
                        className="ml-1 hover:text-gray-900"
                      >
                        <X size={14} />
                      </button>
                    </div>
                  ))}
                </div>
              </div>
              

              <div className="relative">
                <label htmlFor="city" className="block text-sm font-medium text-gray-700 mb-1">
                  US City
                </label>
                <Select
                    className="location-type-select"
                    classNamePrefix="location" 
                    closeMenuOnSelect={true}
                    placeholder="Search for a city..." 
                    isValidNewOption={()=>true}
                    onChange={onCityChange}
                    value={''}
                    options={filteredCities}
                    components={{MenuList}}
                    filterOption={createFilter({ ignoreAccents: false })} 
                    maxMenuHeight={240}
                  />

                   <div className="flex flex-wrap gap-2 mt-2">
                    {formData.city_ids.value.map((city_id) => (
                      <div
                        key={city_id}
                        className="flex items-center gap-1 px-3 py-1 bg-gray-100 rounded-full text-sm text-gray-700"
                      >
                        {getCityById(city_id)?.label}
                        <button
                          onClick={() => removeCity(city_id)}
                          className="ml-1 hover:text-gray-900"
                        >
                          <X size={14} />
                        </button>
                      </div>
                    ))}
                  </div>

              </div>
            </div>
          )}
          {activeTab === 'experience' && (
            <div className="space-y-4">
              {
                !selectedTitle?.label ?
                <p className="text-sm text-gray-800">
                  Please select the Role on the General tab before setting the Years of Experience requirements.
                </p>  :
                <p className="text-sm text-gray-800">Please provide the minimum years of experience a profile 
                should have in these roles to qualify. [Optional]</p>
              }
              {renderExperience()}
            </div>
          )}


          {activeTab === 'custom' && (
            <div className="space-y-4">
              {/* Instructional Message */}
              <p className="text-sm text-gray-800">
                Please provide a comma separated list of any additional filters you want to be applied. For e.g.: "backend only, US bachelors only"
              </p>
              
              {/* Multiline Text Input */}
              <div>
                <textarea
                  id="custom"
                  name="custom"
                  onChange={onInputChange}
                  value={formData?.custom?.value || ''}
                  rows={4}
                  className="w-full p-2 border border-gray-300 rounded-md focus:ring-1 focus:ring-green-500 focus:border-green-500"
                  placeholder="Enter your custom filters here..."
                />
              </div>
            </div>
          )}

          {activeTab === 'booleans' && (
            <div className="space-y-4">
              <CheckBox 
                id={'skip_bootcamp'} 
                checked={formData?.skip_bootcamp?.value} 
                onChange={onCheckBoxChange}>
                <div className="text-sm text-gray-800">Skip bootcamp graduates</div>
              </CheckBox>
              <CheckBox 
                id={'skip_if_started_job_1y_ago'} 
                checked={formData?.skip_if_started_job_1y_ago?.value} 
                onChange={onCheckBoxChange}>
                <div className="text-sm text-gray-800">Skip if started a new job less than 1y ago</div>
              </CheckBox>
              <CheckBox 
                id={'skip_tenure_at_latest'} 
                checked={!!formData?.skip_tenure_at_latest?.value} 
                onChange={onCheckBoxChange}>
                <div className="text-sm text-gray-800 inline-flex flex-wrap">
                Skip if at the latest employer for more than 
                <div className="skip_tenure-select-container">
                  <Select
                    className="skip_tenure-select"
                    classNamePrefix="skip_tenure" 
                    options={SKIP_TENURE_VALUES}
                    value={skipTenureValue}
                    onChange={onSkipTenureSelect}
                    maxMenuHeight={'14rem'}
                  />
                </div>
                years
                </div>
              </CheckBox>
            </div>
          )}

          {activeTab === 'immigration' && (
            <div className="space-y-4">
              <CheckBox 
                id={'us_bachelors_only'} 
                checked={formData?.us_bachelors_only?.value} 
                onChange={onCheckBoxChange}>
                <div className="text-sm text-gray-800">Candidates with US Bachelors only</div>
              </CheckBox>
              <CheckBox 
                id={'us_resident_years'} 
                checked={!!formData?.us_resident_years?.value} 
                onChange={onCheckBoxChange}>
                <div className="text-sm text-gray-800 inline-flex flex-wrap">
                Non US Bachelors, residing in USA for 
                <div className="us_resident-select-container">
                  <Select
                    className="us_resident-select"
                    classNamePrefix="us_resident" 
                    options={US_RESIDENT_VALUES}
                    value={usResidentValue}
                    onChange={onUsResidentSelect}
                    maxMenuHeight={'14rem'}
                  />
                </div>
                years
                </div>
              </CheckBox>
            </div>
          )}


          {activeTab === 'lists' && (
            <div className="space-y-4">
              {/* Instructional Message */}
              <p className="text-sm text-gray-800">
                Add companies whose current employees you would like us to skip.             
              </p>
              <input
                  type="text"
                  onKeyDown={handleKeyDown}
                  placeholder="Enter company names..."
                  className="w-full p-2 border border-gray-300 rounded-md focus:ring-1 focus:ring-green-500 focus:border-green-500"
                />
              <div className="flex flex-wrap gap-2 mt-2">
                {formData.skip_company_names.value.map((name) => (
                  <div
                    key={name}
                    className="flex items-center gap-1 px-3 py-1 bg-gray-100 rounded-full text-sm text-gray-700"
                  >
                    {name}
                    <button
                      onClick={() => removeCompany(name)}
                      className="ml-1 hover:text-gray-900"
                    >
                      <X size={14} />
                    </button>
                  </div>
                ))}
                </div>
            </div>
          )}


        </div>
        <div className='control-container'>
          <button 
            onClick={onSubmitClick}
            className="bg-green-600 cursor-pointer text-white px-4 py-2 rounded-md hover:bg-green-700">
            Save
          </button>
        </div>
    </>
  }

  return (
    <>
      {/* Overlay */}
      <div 
        className={`overlay fixed inset-0 bg-black bg-opacity-30 transition-opacity duration-300 ${
          isOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'
        }`}
      />

      {/* Sliding pane */}
      <Container 
        className={`fixed top-0 right-0 h-full bg-white shadow-lg transform transition-transform duration-300 ease-in-out ${
          isOpen ? 'translate-x-0' : 'translate-x-full'
        }`}
      >
        {renderContent()}
      </Container>
    </>
  );
};

export default SlidingPane;

import React, { useState } from "react";
import styled from 'styled-components';
import Spinner from "./Spinner";
import { getSourcedProfileDetails } from "../services";
import { MapPin, ChevronDown, ChevronUp, MessageCircle, ThumbsUp, ThumbsDown } from 'lucide-react';
import { FaLinkedin } from "react-icons/fa";
import { BiSolidMessageRounded } from "react-icons/bi";


const Container = styled.div`
    box-sizing: border-box;
    width: 100%;
    margin-bottom: 1rem;
    /* border-radius: 0.2rem; */
    overflow: hidden;
    font-family: Roboto;
    font-weight: 400;
    .matches-role {
      padding: 0.1rem 0.5rem;
      min-width: auto;
      width: fit-content;
      height: auto;
      opacity: 0.7;
      font-weight: 500;
      position: absolute;
      right: 0;
      bottom: 0;
      font-size: 0.8rem;
      cursor: pointer;
      &:hover {
        opacity: 0.9;
      }
      &:active {
        opacity: 0.7;
      }
      &:focus {
        outline: none;
      }
    }
    .name-link {
      display: inline-flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 0.2rem;
      svg {
        display: inline-block;
        height: 1rem;
        width: 1rem;
      }
    }

    .banner {
      display: flex;
      box-sizing: border-box;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      padding: 0 1rem;
      font-size: 1rem;
      min-height: 3.5rem;
      position: relative;
      &:hover {
        .name { 
          .editable-name {
            svg.edit-icon {
              display: inherit !important;
            }
          }
        }
      }
      .filter_cnt {
        /* position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); */
        font-size: 0.75rem;
        font-weight: 400;
        padding: 0.375rem !important;
        border-radius: 2px;
      }
      .label  {
        display: flex;
        gap: 1rem;
        align-items: center;
        span {
          font-size: 0.9rem;
          font-weight: 400;
          opacity: 0.8;
        }
        div {
          padding: 0;
          &.expand-arrow {
            cursor: pointer;
          }
        }

      }
      /* &:hover {
        .label {
          display: block !important;
        }
      } */
      
      .name {
        font-weight: 400;
        min-width: 10rem;
        .editable-name {
          font-size: inherit;
          svg.edit-icon {
            width: 0.8rem;
            margin-left: 0.4rem;
            display: none;
          }
        }
        a {
          text-decoration: none;
          color: black;
          &.hr-link {
            border: 1px solid black;
            border-radius: 2px;
            display: inline-block;
            margin-right: 0.5rem;
            padding: 1px 0 0 1px;
          }
          &:hover {
            text-decoration: underline;
          }
        }
        .honest-resume {
          cursor: pointer;
          margin-left: 0.3rem;
          margin-bottom: -0.1rem;
        }
      }  
    }
    .loading-assessment {
      min-height: 7.25rem;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: white;
    }
    .details {
      height: 0;
      overflow: hidden;
      width: 100%;
      /* background-color:  white; */
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0;
      position: relative;
      flex-direction: column;
      .details-description {
        padding: 1rem;
      }
      .details-footer {
        display: flex;
        justify-content: space-between;
        padding: 1rem;
        width: 100%;
      }
      .mark-label {
        /* position: absolute; */
        /* bottom: 0rem;
        right: 0rem; */
        margin: 0 1rem 1rem;
        font-size: 0.8rem;
        align-self: flex-end;
      }
      .message {
        /* position: absolute; */
        /* bottom: 0rem;
        right: 0rem; */
        margin: 0 1rem 1rem;
        font-size: 0.8rem;
        border: 2px solid #000;
        align-self: flex-end;
      }
      .filter-wrapper {
        flex: 1;
        margin: 1rem 0;
        font-size: 0.9rem;
        position: relative;
        h3 {
          margin: 0 0 0.7rem;
          font-size: 0.9rem;
          font-weight: 600;
        }
        .employments {
          margin: 0;
        }
        .education {
          margin: 1.5rem 0 2rem;
        }

        .employment-item {
          font-family: Roboto;
          font-weight: 400;
          margin-bottom: 0.3rem;
          &.extra-margin {
            margin-bottom: 0.7rem;
          }
          .employment-item-title-container {
            display: inline-flex;
            width: 25rem;
            max-width: 25rem;
            padding-right: 1rem;
            align-items: center;
            label.employment-item-title {
              overflow: hidden;
              max-width: 100%;
              display: inline-block;
              white-space: nowrap;
              text-overflow: ellipsis; 
              -o-text-overflow: ellipsis;
            }
          }
          .employment-item-duration-container { 
            width: fit-content;
            display: inline-flex;
          }
          .employment-item-company-container {
            display: inline-flex;
            width: 20rem;
            max-width: 20rem;
            align-items: center;
            padding-right: 1rem;
            label.employment-item-company {
              /* text-transform: uppercase; */
              padding-left: 0.3rem;
              overflow: hidden;
              max-width: 100%;
              display: inline-block;
              white-space: nowrap;
              text-overflow: ellipsis; 
              -o-text-overflow: ellipsis;
            }
          }
        }
        .why-john {
          position: absolute;
          top: 0rem;
          right: 0rem;
          font-size: 0.8rem;
          border-bottom: 1px dotted #000;
          cursor: pointer;
        }
        .footer {
          margin-top: 2rem;
          .nice-to-haves {
            margin-top: 1rem;
            font-size: 0.75rem;
            font-weight: 400;
          }
        }
        /* > button {
            padding: 0 1rem;
            font-size: 0.8rem;
            height: 2.1rem;
            min-width: 7.5rem;
            align-self: flex-end;
            position: absolute;
            right: 0;
            bottom: 0;
          } */
        .header {
          /* display: flex;
          justify-content: space-between;
          margin-bottom: 0.5rem; */
          .contacts {
            font-weight: 400;
            display: flex;
            flex-direction: row;
            gap: 1.9rem;  
            /* margin-bottom: 0.5rem;  */
            .contact {
              display: flex;
              gap: 0.5rem;
              align-items: center;
              &.last-modified {
                font-size: 0.85rem;
                align-self: baseline;
                font-weight: 300;
              }
              svg {
                height: 1rem; 
                width: 1rem;
              }
            }     
            a {
              color: black;
              text-decoration: none;
              font-weight: 300;
              font-size: 0.8rem;
              line-height: 1.25rem;
            }
          }
        }
  
        .attributes {
          font-weight: 500;
          display: flex;
          flex-direction: column;
          gap: 0.2rem;  
          margin-top: 1.3rem;
          .filter {
            font-family: Roboto;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5rem;
            text-align: left;
          }
          /* margin-bottom: 0.5rem;         */
        }
        .inference {
          display: flex;
          flex-flow: wrap;
          gap: 0.4rem;
          /* margin-top: 2.2rem; */
          margin-top: 1rem;
        }
        .filters {
          flex: 1;
          display: flex;
          flex-flow: wrap;
          gap: 2rem;
          >div {
            /* flex: 0.5; */
            div.filter {
              font-weight: 400;
              margin-right: 1rem;
              margin-bottom: 0.1rem;
              display: flex;
              font-size: 0.75rem;
              align-items: center;
              gap: 1rem;
            }
          }
         
        }
      }
    }

    &.red {
      background-color: var(--color-red-0);
      .banner {
        .filter_cnt {
          border: 1px solid var(--color-red-1);
        }
      }
    }
    &.grey {
      background-color: var(--color-gray-2);
    }
    &.green {
      background-color: var(--color-green-0);
      .banner {
        .filter_cnt {
          border: 1px solid var(--color-green-1);
        }
      }
    }
    &.expanded {
      border: 1px solid hsl(0, 0%, 80%);
      /* .banner .label {
        display: block;
      } */
      .details {
        height: auto;
        background-color: white;       
      }
    }
`

export const Pill = styled.div`
  margin: 0.1rem 0 0 0.5rem;
  font-size: 0.7rem;
  border-radius: 1rem;
  padding: 0.3rem 0.5rem;
  box-sizing: border-box;
  font-weight: 600;
  height: fit-content;
  width: fit-content;
  display: inline-block;
  &.green {
    background-color: var(--color-green1);
    color: white;
    border: 1px solid var(--color-green1);
  }
  &.red {
    background-color: var(--color-red-1);
    color: white;
    border: 1px solid var(--color-red-1);
  }
  &.grey {
    background-color: white;
    border: 1px solid #000000;
  }
  &.true-grey {
    background-color: var(--color-gray1);
    border: 1px solid var(--color-gray1);
    color: white;
  }
  &.orange {
    color: white;
    background-color: rgb(203,109,46);
  }
`

const SourcedProfileListItem = ({ 
  data, 
  profileStore, 
  roleId, 
  onMessageYesClick, 
  onMessageNoClick,
  onViewMessageClick,
  skipped
}) => {
  const [ expanded, setExpanded ] = useState(false);
  const [ isLoading, setIsLoading ] = useState(false);
  const [ assessment, setAssessment ] = useState(null);

  const onAssessmentExpand = async () => {
    setExpanded(true);
    if (profileStore[data.profile_id]) {
      setAssessment(profileStore[data.profile_id]);
    } else {
      setIsLoading(true);
      try {
        const res = await getSourcedProfileDetails(data.profile_id, roleId);
        profileStore[data.profile_id] = res;
        setAssessment(res);
      } catch (err) {
        console.log(err);
      } finally {
        setIsLoading(false);
      }
    }
  }

  const getClassName = () => {
    let className = '';
    if (expanded) {
      className += ' expanded'
    }
    className += ' rounded-lg';
    return className;
  }

  const onMessageButtonYesClick = async () => {
    await onMessageYesClick(roleId, data.profile_id);
    if (assessment) {
      assessment.dt_messaged = (new Date()).toLocaleDateString("en-US", {month: 'short', day: '2-digit', year: 'numeric'});
    }
  }

  const onMessageButtonNoClick = () => {
    onMessageNoClick(roleId, data.profile_id);
  }

  const renderMarkButton = () => {
    const firstName = data.name?.split(' ')[0];
    if (assessment?.dt_messaged) return (
      <div className="mark-label">{firstName} was messaged on {assessment?.dt_messaged}</div>
    );
    if (assessment?.dt_skipped) return (
      <div className="mark-label">{firstName} was skipped on {assessment?.dt_skipped}</div>
    );
    if (skipped) return null;
    return (
      <div className="flex justify-end items-end w-full">
        <span className="mr-3 text-gray-800">Did you message {firstName}?</span>
        <div 
          onClick={onMessageButtonYesClick} 
          className="text-blue-500 hover:text-blue-700 cursor-pointer mr-2 flex items-center">
            Yes <ThumbsUp className="ml-1 w-4 h-4" />
        </div>
        <div
          className="text-blue-500 hover:text-blue-700 cursor-pointer flex items-center"
          onClick={onMessageButtonNoClick}>
          No <ThumbsDown className="ml-1 w-4 h-4" />
        </div>
      </div>
    )
  }

  const renderMessageButton = () => {
    if (onViewMessageClick) return (
      <BiSolidMessageRounded
       onClick={() => onViewMessageClick(roleId, data.profile_id)} 
       className="cursor-pointer w-5 h-5 mr-3" 
       color="var(--color-green-700)"/>
      // <MessageCircle onClick={() => onViewMessageClick(roleId, data.profile_id)} className="cursor-pointer w-5 h-5 mr-3" />
    )
    return <div/>
  }

  const renderDetails = () => {
    if (isLoading) return (
      <div className="loading-assessment">
        <Spinner color="var(--color-green-700)" 
      secondaryColor="rgba(51,128,63,0.4)" size="2rem"/>
      </div>
    )
    return <div className="details">
      <div className="details-description" dangerouslySetInnerHTML={{__html: assessment?.summary}}/>
      <div className="details-footer">
        {renderMessageButton()}
        {renderMarkButton()}
      </div>
    </div>
  }
  
  return (
    <Container className={getClassName()}>
      <div className="bg-green-50 border border-t rounded-lg p-4 flex items-center justify-between shadow-sm hover:bg-green-100 cursor-pointer">
        <div className="font-medium text-gray-800" style={{flex: 0.5}}>
          <a href={data.linkedin_url} className="name-link" rel="noreferrer" target="_blank">
          <FaLinkedin />{data.name}
          </a>
        </div>
        <div className="font-medium text-gray-800">
          {data.sourced_date}
        </div>
        <div className="flex items-center text-gray-600" style={{flex: 0.5, justifyContent: 'flex-end'}}>
          <MapPin className="mr-2" size={18} />
          <span className="mr-2">{`${data.location_txt}`}</span>
          {
            expanded ?
            <ChevronUp  onClick={() => setExpanded(false)} size={18} className="ml-2" /> :
            <ChevronDown  onClick={onAssessmentExpand} size={18} className="ml-2" />
          }
        </div>
      </div>
      {renderDetails()} 
    </Container>
  );
}

export default SourcedProfileListItem;
import React, { useState } from 'react';
import { Mail, MessageCircle } from 'lucide-react';
import Footer from '../components/FooterV2';
import NavBar from '../components/NavBarV2';
import { contactUs } from "../services";
import styled from 'styled-components';
const Container = styled.div`
  .content-section {
    min-height: calc(100vh - 5.5rem);
  }
  .error-message {
    font-size: 0.8rem;
    color: var(--color-red-1);
    margin-top: 0.5rem;
  }
`

const ContactPage = () => {
  const [isSent, setIsSent] = useState(false);
  const [formData, setFormData] = useState({
    name: { value: '', error: false },
    email: { value: '', error: false },
    message: { value: '', error: false }
  });

  const validateFormData = (data) => {
    const validatedData = {...data};
    let isValid = true;
    Object.keys(validatedData).forEach(key => {
      if(validatedData[key].value.trim() === '') {
        validatedData[key].error = true;
        isValid = false;
      } else {
        validatedData[key].error = false;
      }
    });
    setFormData(validatedData);
    return isValid;
  } 

  const clearFormData = () => {
    setFormData({
      name: { value: '', error: false },
      email: { value: '', error: false },
      message: { value: '', error: false }
    });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateFormData(formData)) {
      setIsSent(true);
      await contactUs(
        formData?.name?.value,
        formData?.email?.value,
        formData?.message?.value
      );
      clearFormData();
    } 
  };

  const handleChange = (e) => {
    const data = {...formData};
    data[e.target.name].value = e.target.value;
    data[e.target.name].error = false;
    setFormData(data);
  };

  const renderForm = () => {
    if (isSent) return <div className="max-w-2xl mx-auto bg-white rounded-lg shadow-md p-8 text-xl text-gray-600 text-center">
      <p>
        Thank you for reaching out to us.      
      </p>
      <p>
        You will get a response from HR team within 5 hours.
      </p>        
    </div>;
  return <div className="max-w-2xl mx-auto bg-white rounded-lg shadow-md p-8">
    <h2 className="text-2xl font-bold mb-6 text-gray-800">Send us a Message</h2>
    <form onSubmit={handleSubmit}>
      <div className="mb-6">
          <label className="block text-gray-700 mb-2" htmlFor="name">
            Your Name
          </label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name.value}
            onChange={handleChange}
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-600"
            required
          />
          {
            formData?.name?.error &&
            <div className="error-message">This field cannot be empty.</div>
          }
        </div>
        <div className="mb-6">
          <label className="block text-gray-700 mb-2" htmlFor="email">
            Your Email
          </label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email.value}
            onChange={handleChange}
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-600"
            required
          />
          {
            formData?.email?.error &&
            <div className="error-message">This field cannot be empty.</div>
          }
        </div>

      <div className="mb-6">
        <label className="block text-gray-700 mb-2" htmlFor="message">
          Message
        </label>
        <textarea
          id="message"
          name="message"
          value={formData.message.value}
          onChange={handleChange}
          rows={5}
          className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-600"
          required
        />
        {
          formData?.message?.error &&
          <div className="error-message">This field cannot be empty.</div>
        }
      </div>
      <button
        type="submit"
        className="cursor-pointer bg-green-600 text-white px-6 py-3 rounded-md hover:bg-green-700 w-full md:w-auto"
      >
        Send Message <MessageCircle className="inline ml-2 h-5 w-5" />
      </button>
    </form>
  </div>
  }

  return (
    <Container className="min-h-screen flex flex-col">
      <NavBar/>

      {/* Contact Section */}
      <div className="flex-grow py-16 px-4 content-section">
        <div className="max-w-6xl mx-auto">
          <h1 className="text-4xl font-bold text-center mb-8 text-gray-800">
            Get in Touch
          </h1>
          <p className="text-xl text-gray-600 text-center mb-12 max-w-2xl mx-auto">
            Have questions about our AI-powered talent sourcing? We're here to help you revolutionize your hiring process.
          </p>

          <div className="max-w-2xl mx-auto mb-16">
            <div className="bg-white p-6 rounded-lg shadow-md text-center">
              <Mail className="w-12 h-12 text-green-600 mx-auto mb-4" />
              <h3 className="text-xl font-semibold mb-2">Email Us</h3>
              <p className="text-gray-600">
              <a href='mailto:info@honestresume.io' 
              target="__blank"
              className="text-gray-600 hover:text-gray-800">info@honestresume.io</a>
              </p>
            </div>
          </div>

          {/* Contact Form */}
          {renderForm()}
          
        </div>
      </div>
      <Footer/>
    </Container>
  );
};

export default ContactPage;
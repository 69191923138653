import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import "./SourcingRoleModalV2.css";
import LoadingScreen from './LoadingScreen';
import CheckBox from './CheckBoxV2';
import DomainSelect from './DomainSelect';
import { ChevronRight } from 'lucide-react';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 40%;
  max-width: 30rem;
  overflow-y: auto;
  .close-button {
    position: absolute;
    top: 50%;
    left: 1rem;
    transform: translateY(-50%);
    cursor: pointer;
  }

  .input-container {
    display: flex;
    gap: 0.5rem;
    input[type="number"] {
      max-width: 7.5rem;
    }
    input[type="number"]::-webkit-inner-spin-button {
      opacity: 1 !important;
    }
  }

  .location-type-select {
    .location__indicators {
      .location__indicator-separator {
        display: none;
      }
    }
    .location__control {
      padding: calc(var(--spacing)* 0.5) calc(var(--spacing)* 2);
      background-color: var(--color-white);
      border-color: var(--color-gray-300);
      border-style: var(--tw-border-style);
      border-width: 1px;
      border-radius: var(--radius-md);
      width: 100%;
      --tw-ease: var(--ease-in-out);
      .location__value-container {
        padding: 0;
        /* min-height: 3.5rem; */
        .location__input {
          min-height: auto !important;
        }
        .location__multi-value {
          background: white;
          border: 1px solid black;
          >div {
            background: white;
            svg {
              opacity: 0.6;
            }
          }
        }
      }
      .location__multi-value__remove:hover {
        background-color: #ccc;
        color: black;
      }
      .location__placeholder {
        color:  var(--color-gray1);
        font-size: 1rem;
        padding: 0;
      }
    }
    .location__menu {
      margin-top: -1px !important;
      padding: 0 !important;
      .location__option {
          font-size: 0.9rem !important;
        }
      .location__menu-list {
        padding: 0 !important;

      }
    }
  }
  
  .sourcing_title-select {
    width: 100%;
    .sourcing_title__control--is-disabled {
      background: transparent !important;
      .sourcing_title__indicators {
        display: none !important;
      }
    }
    .sourcing_title__control {

      box-shadow: none;
      /* height: 3.4rem; */
      color: black;
      /* font-weight: 400;
      font-family: Roboto;
      padding-left: 0; */


      padding: calc(var(--spacing)* 0.5) calc(var(--spacing)* 2);
      background-color: var(--color-white);
      border-color: var(--color-gray-300);
      border-style: var(--tw-border-style);
      border-width: 1px;
      border-radius: var(--radius-md);
      width: 100%;
      --tw-ease: var(--ease-in-out);


      .sourcing_title__value-container {
        padding: 2px 0;
      }
      .sourcing_title__placeholder {
        color: var(--color-gray1);
        font-size: 1em;
      }
      .sourcing_title__single-value {
        color: black; 
        /* font-weight: 400 !important;
        font-family: Roboto; */
        font-size: 1em;
      }
      .sourcing_title__indicators {
        .sourcing_title__indicator-separator {
          display: none;
        }
        .sourcing_title__dropdown-indicator {
          color: black !important;
          font-weight: 400 !important;
          font-family: Roboto;
          opacity: 0.5;
          padding: 0.5rem 0;
        }
      }
    }
    .sourcing_title__menu {
      margin-top: 0 !important;
      padding: 0 !important;
      .sourcing_title__menu-list {
        padding: 0 !important;
        .sourcing_title__option {
          color: black;
          height: 2rem;
          /* font-weight: 400 !important;
          font-family: Roboto; */
          display: flex;
          align-items: center;
          padding-left: 0.5rem;
          padding: 0.5rem;
          font-size: 1em;
          &.sourcing_title__option--is-selected {
            color: white;
          }
          &.sourcing_title__option--is-focused {
            
          }
        }
      }
    }
  }
  .error-message {
    font-size: 0.8rem;
    color: var(--color-red-1);
    margin-top: 0.5rem;
  }
  .control-container {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex: 1;
    button {
      width: 100%;
      margin: calc(var(--spacing)* 6);
    }
  }
  .loading-screen {
    background: white !important;
  }
  .funding-item {
    display: flex;
    gap: 0.5rem;
    margin-bottom: 1rem;
    >label {
      min-width: 4.2rem;
    }
  }
  .funding-item-container {
    gap: 2rem;
  }
  .item-horizontal-container {
    display: flex;
    gap: 2rem;
    >div {
      flex: 1;
    }
  }
`

const REQUIRED_FIELDS = [
  'company_name', 
  'crunchbase_url', 
  'url', 
  'linkedin_url',
  'location',
  'year_founded'
]


// Sliding Pane Component
const SlidingPane = ({ isOpen, loading, onClose, onSubmit, data }) => {
  const [activeTab, setActiveTab] = useState('general');
  const [formData, setFormData] = useState();
  const divRef = useRef(null);

  useEffect(() => {
    if (isOpen) {
      divRef.current.scroll({top: 0});
      setActiveTab('general');
      clearFormData(data);
    }
  }, [data, isOpen])

  const validateFormData = (data) => {
    const validatedData = {...data};
    let isValid = true;
    REQUIRED_FIELDS.forEach(key => {
      if (validatedData[key]?.value === '') {
        validatedData[key].error = 'This field cannot be empty.';
        isValid = false;
      }
    })
    setFormData(validatedData);
    return isValid;
  } 

  const clearFormData = (data) => {
    setFormData({
      company_name: { value: data?.company_name || '', error: false },
      synonyms: { value: data?.synonyms || '', error: false },
      domain: { value: data?.domain || '', error: false },
      url: { value: data?.url || '', error: false },
      crunchbase_url: { value: data?.crunchbase_url || '', error: false },
      linkedin_url: { value: data?.linkedin_url || '', error: false },
      location: { value: data?.location || '', error: false },
      revenue_range: { value: data?.revenue_range || '', error: false },
      number_of_employees: { value: data?.number_of_employees || '', error: false },
      year_founded: { value: data?.year_founded || '', error: false },
      year_ipo: { value: data?.year_ipo || '', error: false },
      dt_acquired: { value: data?.dt_acquired || '', error: false },
      funding_history: { value: data?.funding_history || {}, error: false },
      is_public: { value: data?.is_public || false, error: false },
      is_closed: { value: data?.is_closed || false, error: false },
    });
  }

  const onInputChange = (e) => {
    const data = {...formData};
    const name  = e.target.name;
    const value = e.target.value;
    if (name.startsWith('seed') || name === 'pre-seed' ||  name.startsWith('series')) {
      if (e.target.type === 'number' && value.length > 4) {
        return;
      }
      data.funding_history.value[name] = value;
    } else {
      data[name].value = value;
      data[name].error = false;
    }
    setFormData(data);
  }

  const onCheckBoxChange = (e) => {
    const data = {...formData};
    data[e.target.name].value = e?.target?.checked;
    setFormData(data);
  }

  const onDomainSelect = (value) => {
    const data = {...formData};
    data.domain.value = value;
    data.domain.error = false;
    setFormData(data);
  }

  const onSubmitClick = async () => {
    if (validateFormData(formData)) {
      //setUploading(true);
      const data = {};
      for (let key in formData) {
        if (formData[key]?.value !== '') {
          data[key] = formData[key]?.value;
        }
      }
      await onSubmit(data);
      clearFormData();
      //setUploading(false);  
    } else {
      setTimeout(() => {
        const errorInput = document.querySelector('div.error-red-message')
        if (errorInput) {
          errorInput.scrollIntoView();
        }
      }, 50)
    }
  }

  const renderContent = () => {
    if (loading) return <LoadingScreen loading={true} color="var(--color-green-700)" secondaryColor="rgba(51,128,63,0.4)"/>;
    return <>
        {/* Header */}
        <div className="flex relative items-center justify-center px-6 py-4 border-b">
          <ChevronRight onClick={onClose} className='close-button' strokeWidth={1.5} />
          <h2 className="text-xl font-semibold text-gray-800">Company Cache</h2>
        </div>

        {/* Tabs */}
        <div className="border-b">
          <div className="flex">
            <button 
              onClick={() => setActiveTab('general')}
              className={`px-6 py-3 text-sm font-medium border-b-2 transition-colors cursor-pointer ${
                activeTab === 'general' 
                  ? 'text-gray-800 border-green-600' 
                  : 'text-gray-500 border-transparent hover:text-gray-700 hover:border-gray-300'
              }`}
            >
              Company Info
            </button>
            <button 
              onClick={() => setActiveTab('location')}
              className={`px-6 py-3 text-sm font-medium border-b-2 transition-colors cursor-pointer ${
                activeTab === 'location' 
                  ? 'text-gray-800 border-green-600' 
                  : 'text-gray-500 border-transparent hover:text-gray-700 hover:border-gray-300'
              }`}
            >
              Funding History
            </button>
          </div>
        </div>

        {/* Tab Content */}
        <div className="p-6">
          {activeTab === 'general' && (
            <div className="space-y-4">
              <div>
                <label htmlFor="company_name" className="block text-sm font-medium text-gray-700 mb-1">
                  Company Name*
                </label>
                <input
                  type="text"
                  id="company_name"
                  name="company_name"
                  onChange={onInputChange}
                  value={formData?.company_name?.value || ''}
                  className="w-full p-2 border border-gray-300 rounded-md focus:ring-1 focus:ring-green-500 focus:border-green-500"
                />
                {
                  formData?.company_name?.error &&
                  <div className="error-message">{formData?.company_name?.error}</div>
                }
              </div>
              <div>
                <label htmlFor="synonyms" className="block text-sm font-medium text-gray-700 mb-1">
                Synonyms (pipe separated)
                </label>
                <input
                  type="text"
                  id="synonyms"
                  name="synonyms"
                  onChange={onInputChange}
                  value={formData?.synonyms?.value || ''}
                  className="w-full p-2 border border-gray-300 rounded-md focus:ring-1 focus:ring-green-500 focus:border-green-500"
                />
              </div>
              <div>
                <label htmlFor="url" className="block text-sm font-medium text-gray-700 mb-1">
                  Company url*
                </label>
                <input
                  type="text"
                  id="url"
                  name="url"
                  onChange={onInputChange}
                  value={formData?.url?.value || ''}
                  className="w-full p-2 border border-gray-300 rounded-md focus:ring-1 focus:ring-green-500 focus:border-green-500"
                />
                {
                  formData?.url?.error &&
                  <div className="error-message">{formData?.url?.error}</div>
                }
              </div>
              <div>
                <label htmlFor="crunchbase_url" className="block text-sm font-medium text-gray-700 mb-1">
                  Crunchbase url*
                </label>
                <input
                  type="text"
                  id="crunchbase_url"
                  name="crunchbase_url"
                  onChange={onInputChange}
                  value={formData?.crunchbase_url?.value || ''}
                  className="w-full p-2 border border-gray-300 rounded-md focus:ring-1 focus:ring-green-500 focus:border-green-500"
                />
                {
                  formData?.crunchbase_url?.error &&
                  <div className="error-message">{formData?.crunchbase_url?.error}</div>
                }
              </div>
              <div>
                <label htmlFor="linkedin_url" className="block text-sm font-medium text-gray-700 mb-1">
                  LinkedIn url*
                </label>
                <input
                  type="text"
                  id="linkedin_url"
                  name="linkedin_url"
                  onChange={onInputChange}
                  value={formData?.linkedin_url?.value || ''}
                  className="w-full p-2 border border-gray-300 rounded-md focus:ring-1 focus:ring-green-500 focus:border-green-500"
                />
                {
                  formData?.linkedin_url?.error &&
                  <div className="error-message">{formData?.linkedin_url?.error}</div>
                }
              </div>
              <div>
                <label htmlFor="location" className="block text-sm font-medium text-gray-700 mb-1">
                  HQ Location*
                </label>
                <input
                  type="text"
                  id="location"
                  name="location"
                  onChange={onInputChange}
                  value={formData?.location?.value || ''}
                  className="w-full p-2 border border-gray-300 rounded-md focus:ring-1 focus:ring-green-500 focus:border-green-500"
                />
                {
                  formData?.location?.error &&
                  <div className="error-message">{formData?.location?.error}</div>
                }
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Domain
                </label>
                <DomainSelect onSelect={onDomainSelect} value={formData?.domain?.value} />
              </div>

              <div className='item-horizontal-container w-full'>
                <div>
                  <label htmlFor="revenue_range" className="block text-sm font-medium text-gray-700 mb-1">
                  Revenue Range
                  </label>
                  <input
                    type="text"
                    id="revenue_range"
                    name="revenue_range"
                    onChange={onInputChange}
                    value={formData?.revenue_range?.value || ''}
                    className="w-full p-2 border border-gray-300 rounded-md focus:ring-1 focus:ring-green-500 focus:border-green-500"
                  />
                  {
                    formData?.revenue_range?.error &&
                    <div className="error-message">{formData?.revenue_range?.error}</div>
                  }
                </div>
                <div>
                  <label htmlFor="number_of_employees" className="block text-sm font-medium text-gray-700 mb-1">
                  Number of Employees
                  </label>
                  <input
                    type="text"
                    id="number_of_employees"
                    name="number_of_employees"
                    onChange={onInputChange}
                    value={formData?.number_of_employees?.value || ''}
                    className="w-full p-2 border border-gray-300 rounded-md focus:ring-1 focus:ring-green-500 focus:border-green-500"
                  />
                  {
                    formData?.number_of_employees?.error &&
                    <div className="error-message">{formData?.number_of_employees?.error}</div>
                  }
                </div>
              </div>
              <div>
                <label htmlFor="year_founded" className="block text-sm font-medium text-gray-700 mb-1">
                Year Founded* 
                </label>
                <input
                  type="text"
                  id="year_founded"
                  name="year_founded"
                  onChange={onInputChange}
                  value={formData?.year_founded?.value || ''}
                  className="w-full p-2 border border-gray-300 rounded-md focus:ring-1 focus:ring-green-500 focus:border-green-500"
                />
                {
                  formData?.year_founded?.error &&
                  <div className="error-message">{formData?.year_founded?.error}</div>
                }
              </div>
              <div>
                <label htmlFor="dt_acquired" className="block text-sm font-medium text-gray-700 mb-1">
                Year Acquired (Optional)
                </label>
                <input
                  type="text"
                  id="dt_acquired"
                  name="dt_acquired"
                  onChange={onInputChange}
                  value={formData?.dt_acquired?.value || ''}
                  className="w-full p-2 border border-gray-300 rounded-md focus:ring-1 focus:ring-green-500 focus:border-green-500"
                />
              </div>
              <div className='item-horizontal-container mt-2  w-full'>
                <div className='mt-4 max-w-[6rem]'>
                  <CheckBox 
                    id='is_public' 
                    name='is_public' 
                    checked={formData?.is_public?.value} 
                    onChange={onCheckBoxChange}>
                      Is Public
                  </CheckBox>
                </div>
                <div>
                  <label htmlFor="year_ipo" className="block text-sm font-medium text-gray-700 mb-1">
                  Year of IPO if public
                  </label>
                  <input
                    type="text"
                    id="year_ipo"
                    name="year_ipo"
                    onChange={onInputChange}
                    value={formData?.year_ipo?.value || ''}
                    className="w-full p-2 border border-gray-300 rounded-md focus:ring-1 focus:ring-green-500 focus:border-green-500"
                  />
                </div>
              </div>
              <div className='mt-6 max-w-[6rem]'>
                <CheckBox
                  id='is_closed' 
                  name='is_closed' 
                  checked={formData?.is_closed?.value} 
                  onChange={onCheckBoxChange}>
                    Is Closed
                </CheckBox>
              </div>
            </div>
          )}
          {activeTab === 'location' && (
            <div className="space-y-4 flex funding-item-container">
              <div>
                <div className='funding-item'>
                  <label htmlFor="pre-seed" className="block text-sm font-medium text-gray-700 mb-1">
                    Pre-seed
                  </label>
                  <input 
                    name="pre-seed"
                    type="number"
                    min={1900}
                    onChange={onInputChange}
                    value={formData?.funding_history?.value?.['pre-seed'] || ''}
                    className="max-w-[4rem] p-1 border border-gray-300 rounded-md focus:ring-1 focus:ring-green-500 focus:border-green-500"
                  />
                </div>
                <div className='funding-item'>
                  <label htmlFor="seed" className="block text-sm font-medium text-gray-700 mb-1">
                    Seed
                  </label>
                  <input 
                    name="seed"
                    type="number"
                    min={1900}
                    onChange={onInputChange}
                    value={formData?.funding_history?.value?.['seed'] || ''}
                    className="max-w-[4rem] p-1 border border-gray-300 rounded-md focus:ring-1 focus:ring-green-500 focus:border-green-500"
                  />
                </div>
                <div className='funding-item'>
                  <label htmlFor="series a" className="block text-sm font-medium text-gray-700 mb-1">
                    Series A
                  </label>
                  <input 
                    name="series a"
                    type="number"
                    min={1900}
                    onChange={onInputChange}
                    value={formData?.funding_history?.value?.['series a'] || ''}
                    className="max-w-[4rem] p-1 border border-gray-300 rounded-md focus:ring-1 focus:ring-green-500 focus:border-green-500"
                  />
                </div>
                <div className='funding-item'>
                  <label htmlFor="series b" className="block text-sm font-medium text-gray-700 mb-1">
                    Series B
                  </label>
                  <input 
                    name="series b"
                    type="number"
                    min={1900}
                    onChange={onInputChange}
                    value={formData?.funding_history?.value?.['series b'] || ''}
                    className="max-w-[4rem] p-1 border border-gray-300 rounded-md focus:ring-1 focus:ring-green-500 focus:border-green-500"
                  />
                </div>
                <div className='funding-item'>
                  <label htmlFor="series c" className="block text-sm font-medium text-gray-700 mb-1">
                    Series C
                  </label>
                  <input 
                    name="series c"
                    type="number"
                    min={1900}
                    onChange={onInputChange}
                    value={formData?.funding_history?.value?.['series c'] || ''}
                    className="max-w-[4rem] p-1 border border-gray-300 rounded-md focus:ring-1 focus:ring-green-500 focus:border-green-500"
                  />
                </div>
                <div className='funding-item'>
                  <label htmlFor="series d" className="block text-sm font-medium text-gray-700 mb-1">
                    Series D
                  </label>
                  <input 
                    name="series d"
                    type="number"
                    min={1900}
                    onChange={onInputChange}
                    value={formData?.funding_history?.value?.['series d'] || ''}
                    className="max-w-[4rem] p-1 border border-gray-300 rounded-md focus:ring-1 focus:ring-green-500 focus:border-green-500"
                  />
                </div>
              </div>
              <div>
                <div className='funding-item'>
                  <label htmlFor="series e" className="block text-sm font-medium text-gray-700 mb-1">
                    Series E
                  </label>
                  <input 
                    name="series e"
                    type="number"
                    min={1900}
                    onChange={onInputChange}
                    value={formData?.funding_history?.value?.['series e'] || ''}
                    className="max-w-[4rem] p-1 border border-gray-300 rounded-md focus:ring-1 focus:ring-green-500 focus:border-green-500"
                  />
                </div>
                <div className='funding-item'>
                  <label htmlFor="series f" className="block text-sm font-medium text-gray-700 mb-1">
                    Series F
                  </label>
                  <input 
                    name="series f"
                    type="number"
                    min={1900}
                    onChange={onInputChange}
                    value={formData?.funding_history?.value?.['series f'] || ''}
                    className="max-w-[4rem] p-1 border border-gray-300 rounded-md focus:ring-1 focus:ring-green-500 focus:border-green-500"
                  />
                </div>
                <div className='funding-item'>
                  <label htmlFor="series g" className="block text-sm font-medium text-gray-700 mb-1">
                    Series G
                  </label>
                  <input 
                    name="series g"
                    type="number"
                    min={1900}
                    onChange={onInputChange}
                    value={formData?.funding_history?.value?.['series g'] || ''}
                    className="max-w-[4rem] p-1 border border-gray-300 rounded-md focus:ring-1 focus:ring-green-500 focus:border-green-500"
                  />
                </div>
                <div className='funding-item'>
                  <label htmlFor="series h" className="block text-sm font-medium text-gray-700 mb-1">
                    Series H
                  </label>
                  <input 
                    name="series h"
                    type="number"
                    min={1900}
                    onChange={onInputChange}
                    value={formData?.funding_history?.value?.['series h'] || ''}
                    className="max-w-[4rem] p-1 border border-gray-300 rounded-md focus:ring-1 focus:ring-green-500 focus:border-green-500"
                  />
                </div>
                <div className='funding-item'>
                  <label htmlFor="series i" className="block text-sm font-medium text-gray-700 mb-1">
                    Series I
                  </label>
                  <input 
                    name="series i"
                    type="number"
                    min={1900}
                    onChange={onInputChange}
                    value={formData?.funding_history?.value?.['series i'] || ''}
                    className="max-w-[4rem] p-1 border border-gray-300 rounded-md focus:ring-1 focus:ring-green-500 focus:border-green-500"
                  />
                </div>
                <div className='funding-item'>
                  <label htmlFor="series j" className="block text-sm font-medium text-gray-700 mb-1">
                    Series J
                  </label>
                  <input 
                    name="series j"
                    type="number"
                    min={1900}
                    onChange={onInputChange}
                    value={formData?.funding_history?.value?.['series j'] || ''}
                    className="max-w-[4rem] p-1 border border-gray-300 rounded-md focus:ring-1 focus:ring-green-500 focus:border-green-500"
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        <div className='control-container'>
          <button 
            onClick={onSubmitClick}
            className="bg-green-600 cursor-pointer text-white px-4 py-2 rounded-md hover:bg-green-700">
            Save
          </button>
        </div>
    </>
  }

  return (
    <>
      {/* Overlay */}
      <div 
        className={`overlay fixed inset-0 bg-black bg-opacity-30 transition-opacity duration-300 ${
          isOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'
        }`}
      />

      {/* Sliding pane */}
      <Container 
        ref={divRef}
        className={`fixed top-0 right-0 h-full bg-white shadow-lg transform transition-transform duration-300 ease-in-out ${
          isOpen ? 'translate-x-0' : 'translate-x-full'
        }`}
      >
        {renderContent()}
      </Container>
    </>
  );
};

export default SlidingPane;
import React, { useState, useEffect, useRef } from "react";
import styled from 'styled-components';
import { 
  getSourcedRoles,
  setSourcedRoleStatus,
  addSourcedRole,
  updateSourcedRole,
  apiReplayer,
  getSourcedRoleDetails
} from "../services";
import { useAuth } from '../hooks';
import Spinner from "../components/Spinner";
import BlueButton from "../components/BlueButton";
import PageContainer from "../components/PageContainer";
import SourcedRoleListItem from "../components/SourcedRoleListItem";
import SourcingRoleModal from "../components/SourcingRoleModal";
import '@smastrom/react-rating/style.css';
import { useAtom } from 'jotai';
import { titlesAtom } from "../atoms";

const Container = styled.div`
    margin-top: 7rem;
    width: 100%;
    height: 100%;
    padding: 2.5rem;
    box-sizing: border-box;
    align-self: flex-start;
    max-width: 60rem;
    flex: 1;
    display: flex;
    flex-direction: row;
    .content {
      flex: 1;
      position: relative;
      .role-item {
        padding: 0 1rem;
        height: 3rem;
        cursor: pointer;
        width: 45rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &:hover {
          background-color: var(--color-grey-00);
        }
        .role-item-controls {
          display: flex;
          align-items: center;
          justify-content: center;
          gap:0.5rem;
          label {
            font-size: 0.8rem;
          }
          button {
            padding: 0.5rem;
            line-height: 1;
            font-size: 0.8rem;
          }
        }
      }
    }
    .spacer {
      flex: 1;
    }
    .controls {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 1rem;
    }
    .spinner {
      position: fixed;
      right: 0;
      top: 0;
      bottom: 0;
      left: 15rem;
      justify-content: center;
      display: flex;
      align-items: center;
    }
`
const SourcingRoles = () => {
  const [ roles, setRoles ] = useState([]);
  const [ titles ] = useAtom(titlesAtom);
  const [ selectedRole, setSelectedRole ] = useState(null);
  const [ loading, setLoading ] = useState(true);
  const isLoading = useRef(false);
  const [ isRoleModalOpen, setRoleModalOpen ] = useState(false);
  const [loadingRoleDetails, setLoadingRoleDetails] = useState(false);
  
  useAuth();

  useEffect(() => {
    if (!isLoading.current) {
      fetchData();
    }
    return () => {
      setRoles([]);
    }
  }, []);

  const fetchData = async () => {
    setLoading(true);
    isLoading.current = true;
    const dataRoles = await apiReplayer(getSourcedRoles, (res) => !!res.roles);
    if (dataRoles?.roles) {
      setRoles(dataRoles?.roles);
    } else {
      setRoles([]);
    }
    setLoading(false);
    isLoading.current = false;
  }

  const addRoleClick = () => {
    setSelectedRole(null);
    setRoleModalOpen(true);
  }

  const onRoleSave = async (data) => {
    setRoleModalOpen(false);
    setLoading(true);
    isLoading.current = true;
    if (selectedRole) {
      await updateSourcedRole(data, selectedRole?.role_id);
    } else {
      await addSourcedRole(data);
    }
    await fetchData();
    setSelectedRole(null);
  }
  
  const onRoleModalClose = () => {
    setRoleModalOpen(false);
  }

  const onFilterOpen = async (data) => {
    setLoadingRoleDetails(true);
    setRoleModalOpen(true);
    try {
      const res = await getSourcedRoleDetails(data?.role_id);
      if (res) {
        setSelectedRole({role_id: data?.role_id, ...res});
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingRoleDetails(false);
    }
  }

  const onRoleStatusChange = async (role_id, status) => {
    await setSourcedRoleStatus(role_id, status);
  }

  const renderContent = () => {
    if (loading) return (
      <div className="content">
        <div className="spinner">
          <Spinner/>
        </div>
      </div>
    );
    return  <div className="content">
      <BlueButton onClick={ addRoleClick } style={{marginBottom: '1rem'}}>Add Role</BlueButton>
      {
        roles.map((item, i) => (
          <SourcedRoleListItem 
            key={i} 
            data={item} 
            onChange={onRoleStatusChange}
            onFilterOpen={() => onFilterOpen(item)}
          />
        ))
      }
    </div>
  }

  return (
    <>
      <PageContainer title={'Sourcing Roles'} selected={'sourcing-roles'}>
        <Container>
        { renderContent() }
        </Container>
      </PageContainer>
      <SourcingRoleModal 
        isOpen={ isRoleModalOpen }
        data={ selectedRole }
        onSubmit={ onRoleSave }
        onClose={ onRoleModalClose }
        loading={loadingRoleDetails}
      />
    </>
  );
};

export default SourcingRoles;
import React, { useState } from "react";
import styled from "styled-components";
import { Filter, ToggleRight } from 'lucide-react';

const Container = styled.div`
  position: relative;
  > button {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`

const SourcedRoleListItem = ({ data, onChange, onFilterOpen }) => {
  const [checked, setChecked] = useState(data.is_active);
  const toggleRole = () => {
    setChecked(!checked);
    onChange(data.role_id, !checked);
  };
  
  return (
    <Container className="bg-white border-t border rounded-lg p-4 flex items-center justify-between shadow-sm">
      <div className="font-semibold text-gray-800">{data.title}</div>
      <button 
        onClick={onFilterOpen}
        className="text-gray-600 cursor-pointer hover:text-green-600 flex items-center">
        <Filter className="mr-1" size={18} />
        <span> sourcing conditions</span>
      </button>
      <div className="flex items-center space-x-6">
        <button 
          onClick={toggleRole}
          className={`flex cursor-pointer items-center px-3 py-1 rounded-full ${
            checked ? 'bg-green-100 text-green-700' : 'bg-gray-100 text-gray-600'
          }`}
        >
          <ToggleRight className="mr-1" size={18} />
          <span>{checked ? 'Active' : 'Paused'}</span>
        </button>
      </div>
    </Container>
  );
}

export default SourcedRoleListItem;
import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import Select from 'react-select';
import "./sourcingRolesV2.css";
import { useAuth } from '../hooks';
import { 
  getSourcedProfiles,
  getSourcedRoles,
  getMessagedProfiles,
  markMessaged,
  skipProfile,
  getSkippedProfiles
} from "../services";
import LoadingScreen from '../components/LoadingScreen';
import SourcedProfileListItemV2 from "../components/SourcedProfileListItemV2";
import PaginationControl from "../components/PaginationControl";
import StyledModal from "../components/StyledModal";
import PageContainer from '../components/PageContainerV2';
import ProfileFeedback from '../components/ProfileFeedbackV2';
import ViewMessage from '../components/ViewMessageV2';

const ITEMS_PER_PAGE = 10;
const TabsContainer = styled.div`
  .tab-header-message  {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
`

const SelectContainer = styled.div`
  .role-select {
    width: 19rem;
    margin-bottom: 2rem;
    .role__single-value, .role__placeholder, .role__input {
      font-weight: 400 !important;
      padding: 0.5rem 0 0.5rem 0.5rem !important;
      font-size: 0.9rem;
      font-family: Roboto;
      color: black;
    }
    .role__control {
      border-color: hsl(0, 0%, 80%) !important;
      box-shadow: none;
      border-color: var(--color-gray-300);
      border-style: var(--tw-border-style);
      border-width: 1px;
      border-radius: var(--radius-md);
      .role__input-container {
        margin: 0;
        padding: 0;
      }
      .role__indicators {
        .role__indicator-separator {
          display: none;
        }
        .role__dropdown-indicator {
          color: black !important;
          font-weight: 500 !important;
          opacity: 0.5;
        }
      }
    }
    .role__menu {
      z-index: 1;
      margin-top: 0 !important;
      padding: 0 !important;
      .role__menu-list {
        z-index: 12;
        padding: 0 !important;
        .role__option {
          color: black;
          height: auto;
          font-weight: 400 !important;
          display: flex;
          align-items: center;
          padding: 0.5rem 1rem !important;
          font-size: 0.9rem;
          box-sizing: border-box;
          font-family: Roboto;
          &.role__option--is-selected {
            color: white;
          }
          &.role__option--is-focused {
            
          }
        }
      }
    }
  }
`

const HonestResumeDashboard = () => {
  const [ roles, setRoles ] = useState([]);
  const [ loading, setLoading ] = useState(true);
  const isLoading = useRef(false);
  const [ selectedRole , setSelectedRole ] = useState(null);
  const [ profiles, setProfiles ] = useState([]);
  const [ messagedProfiles, setMessagedProfiles ] = useState([]);
  const [ skippedProfiles, setSkippedProfiles ] = useState([]);
  const [ page, setPage ] = useState(1);
  const [ loadingProfiles, setLoadingProfiles ] = useState(false);
  const [ activeTab, setActiveTab ] = useState('sourced');
  const [ sourcedLength, setSourcedLength] = useState(0);
  const [ messagedLength, setMessagedLength] = useState(0);
  const [ skippedLength, setSkippedLength] = useState(0);
  const [ profileFeedbackData, setProfileFeedbackData ] = useState();
  const [ viewMessageData, setViewMessageData ] = useState();
  
  const [ reason, setReason ] = useState(null);
  const profileStore =  useRef({});
  useAuth();
  
  const onPageSelect = (n) => {
    window.scrollTo(0,0);
    setPage(n);
  }

  useEffect(() => {
    if (!isLoading.current) {
      fetchData();
    }
    return () => {
      setSelectedRole(null);
      setRoles([]);
      setProfiles([]);
      setMessagedProfiles([]);
      setMessagedLength(0);
      setSourcedLength(0);
    }
  }, [])
  
  const fetchData = async () => {
    setLoading(true);
    isLoading.current = true;
    try {
      const dataRoles = await getSourcedRoles();
      if (dataRoles?.roles) {
        const roles = dataRoles.roles.filter(item => item.is_active).map(item => ({...item, label: item.title, value: item.role_id }));
        setRoles(roles);
        if (roles.length > 0) {
          const role = roles[0];
          setSelectedRole(role);
          await fetchProfile(role.role_id);
        } 
      }
    } catch(err) {
      setSelectedRole(null);
      setRoles([]);
      setProfiles([]);
      setMessagedProfiles([]);
      setMessagedLength(0);
      setSourcedLength(0);
      // TODO this should notify the user that a problem occurred
    } finally {
      setLoading(false);
      setLoadingProfiles(false);
      isLoading.current = false;
    }
  }

  const normalizeURLs = (items) => {
    items?.forEach(element => {
      if (element && element.linkedin_url) {
        if (!element.linkedin_url.startsWith("https://")) {
          element.linkedin_url = "https://" + element.linkedin_url;
        }
      }
    });
  }

  const fetchProfile = async (id) => {
    window.scrollTo(0,0);
    setLoadingProfiles(true);
    setActiveTab('sourced');
    onPageSelect(1);
    setProfiles([]);
    setMessagedProfiles([]);
    setSkippedProfiles([]);
    setMessagedLength(0);
    setSkippedLength(0);
    setSourcedLength(0);
    try {
      const [dataProfiles, dataMessaged, dataSkipped] = await Promise.all([
        getSourcedProfiles(id),
        getMessagedProfiles(id),
        getSkippedProfiles(id)
      ]);
      if (dataProfiles?.profiles) {
        normalizeURLs(dataProfiles.profiles);
        setProfiles(dataProfiles.profiles);
        setSourcedLength(dataProfiles.profiles?.length);
      }
      if (dataProfiles?.cnt_messaged_profiles !== undefined) {
        setMessagedLength(dataProfiles.cnt_messaged_profiles);
      }
      if (dataMessaged?.messaged_profiles) {
        normalizeURLs(dataMessaged.messaged_profiles);
        setMessagedProfiles(dataMessaged.messaged_profiles);
      }
      if (dataSkipped?.skipped_profiles) {
        normalizeURLs(dataSkipped.skipped_profiles);
        setSkippedProfiles(dataSkipped.skipped_profiles);
      }
      if (dataSkipped?.cnt_skipped_profiles !== undefined) {
        setSkippedLength(dataSkipped.cnt_skipped_profiles)
      }
    } catch (err) {

    } finally {
      setLoadingProfiles(false);
    }
  }

  const onConclusionClose = () => {
    setReason(null);
  }


  const onMessageYesClick = async (roleId, profileId) => {
    try {
      window.scrollTo(0,0);
      setLoadingProfiles(true);
      await markMessaged(profileId, roleId);
      await fetchProfile(roleId);
    } catch (err) {

    } finally {
      setLoadingProfiles(false);
    }
  }

  const onMessageNoClick = (role_id, profile_id) => {
    setProfileFeedbackData({
      role_id,
      profile_id,
    });
  }

  const onViewMessageClick = (role_id, profile_id) => {
    setViewMessageData({
      role_id,
      profile_id,
    });
  }


  const onCopy = async (message) => {
    await navigator.clipboard.writeText(message);
    setViewMessageData(null);
  }


  const onProfileFeedbackSubmit = async (data) => {
    try {
      window.scrollTo(0,0);
      setLoadingProfiles(true);
      setProfileFeedbackData(null);
      await skipProfile(data);
      await fetchProfile(data.role_id);
    } catch (err) {

    } finally {
      setLoadingProfiles(false);
    }
  }
  
  const onRoleSelect = async (role) => {
    if (selectedRole.role_id !== role.role_id) {
      setSelectedRole(role);
      await fetchProfile(role.role_id);
    }
  }

  const getActiveTab = () => {
    if (activeTab === 'sourced') return (<>
    <div className="mt-4 max-w-[60rem]">
      {
        profiles.slice((page-1)*ITEMS_PER_PAGE, page*ITEMS_PER_PAGE)
        .map((item, i) => (
          <SourcedProfileListItemV2 
            key={i+item.profile_id} 
            roleId={selectedRole?.role_id}
            data={item}
            profileStore={profileStore.current}
            onMessageYesClick={onMessageYesClick}
            onMessageNoClick={onMessageNoClick}
            onViewMessageClick={onViewMessageClick}
          />
        ))
      }
      {profiles.length === 0 && (
        <div className="bg-gray-100 p-4 rounded-lg">
          No candidate profiles have been matched yet.
        </div>
      )}
    </div>
    {
      !loading.current &&
      <div className="controls max-w-[60rem]">
        <div/>
        {
          profiles.length < ITEMS_PER_PAGE ?
          <div/> :
          <PaginationControl 
            selected={page} 
            total={Math.ceil(profiles.length/ITEMS_PER_PAGE)} 
            onClick={onPageSelect}/>
        }
      </div>
    }
    </>)
    if (activeTab === 'messaged') return (<>
      <div className="mt-4 max-w-[60rem]">
        {
          messagedProfiles
          .map((item, i) => (
            <SourcedProfileListItemV2 
              key={i+item.profile_id} 
              roleId={selectedRole?.role_id}
              data={item}
              profileStore={profileStore.current}
            />
          ))
        }
        {messagedProfiles.length === 0 && (
          <div className="bg-gray-100 p-4 rounded-lg">
            No candidate have been messaged yet.
          </div>
        )}
      </div>
    </>)
    return (<>
      <div className="mt-4 max-w-[60rem]">
        {
          skippedProfiles
          .map((item, i) => (
            <SourcedProfileListItemV2 
              key={i+item.profile_id} 
              roleId={selectedRole?.role_id}
              data={item}
              profileStore={profileStore.current}
              skipped
            />
          ))
        }
        {skippedProfiles.length === 0 && (
          <div className="bg-gray-100 p-4 rounded-lg">
            No candidate have been skipped yet.
          </div>
        )}
      </div>
    </>)
  }

  const renderContent = () => {
    if (loading) return null;
    if (loadingProfiles) return <>
      <SelectContainer>
        <Select
            className="role-select"
            classNamePrefix="role" 
            placeholder="Select role to screen" 
            options={roles}
            onChange={onRoleSelect}
            value={selectedRole}
          />
      </SelectContainer>

      <div className='loading-screen-container'>
        <LoadingScreen loading={true} 
          color="var(--color-green-700)" 
          secondaryColor="rgba(51,128,63,0.4)"/>
      </div>
    </>
    return (<>
      <div className="flex mb-4">
        Please select an open role to view profiles we have sourced for it.
      </div>
      <SelectContainer>
        <Select
            className="role-select"
            classNamePrefix="role" 
            placeholder="Select role to screen" 
            options={roles}
            onChange={onRoleSelect}
            value={selectedRole}
          />
      </SelectContainer>

        {/* Tabs */}
        <TabsContainer className="border-b max-w-[60rem]">
          <div className="flex">
            <button 
              onClick={() => setActiveTab('sourced')}
              className={`px-6 cursor-pointer py-3 text-sm font-medium border-b-2 transition-colors ${
                activeTab === 'sourced' 
                  ? 'text-gray-800 border-green-600' 
                  : 'text-gray-500 border-transparent hover:text-gray-700 hover:border-gray-300'
              }`}
            >
              Sourced ({sourcedLength})
            </button>
            <button 
              onClick={() => setActiveTab('messaged')}
              className={`px-6 cursor-pointer py-3 text-sm font-medium border-b-2 transition-colors ${
                activeTab === 'messaged' 
                  ? 'text-gray-800 border-green-600' 
                  : 'text-gray-500 border-transparent hover:text-gray-700 hover:border-gray-300'
              }`}
            >
              Messaged ({messagedLength})
            </button>
            <button 
              onClick={() => setActiveTab('skipped')}
              className={`px-6 cursor-pointer py-3 text-sm font-medium border-b-2 transition-colors ${
                activeTab === 'skipped' 
                  ? 'text-gray-800 border-green-600' 
                  : 'text-gray-500 border-transparent hover:text-gray-700 hover:border-gray-300'
              }`}
            >
              Skipped ({skippedLength})
            </button>
            <div className='tab-header-message text-gray-800 text-sm font-medium'>New candidates sourced daily at 6am ET.</div>
          </div>
        </TabsContainer>
        {/* Tab Content */}
        {getActiveTab()}
        {/* <div className="p-6"> */}
      
    </>);
  };

  return (<>
    <PageContainer loading={loading} title='Profiles' selected='profiles'>
      {renderContent()}
    </PageContainer>
    {
      profileFeedbackData &&
      <ProfileFeedback 
        data={profileFeedbackData} 
        onSubmit={onProfileFeedbackSubmit}
        onCancel={() => setProfileFeedbackData(null)}
      />
    } 
    {
      viewMessageData &&
      <ViewMessage
        data={viewMessageData} 
        onCopy={onCopy}
        onClose={() => setViewMessageData(null)}
      />
    }
    <StyledModal 
      isOpen={!!reason}
      onClose={onConclusionClose} 
      modalStyle={{
        width: '40rem',
        minWidth: '40rem',
        padding:'2rem 2rem 1rem',
        top: '40%',
        transform: 'translate(-50%, -50%)'
      }}>
      <div style={{
          fontFamily: 'Roboto',
          fontWeight: 400,
          lineHeight: 1.5,
          fontSize: '1rem'
        }} dangerouslySetInnerHTML={{__html: reason}}/>
    </StyledModal>
  </>);
};

export default HonestResumeDashboard;

import axios from 'axios';
import { getUserToken } from './firebase';
import { getDomain } from '../configuration';



const API = axios.create({
  baseURL: await getDomain()
});

API.interceptors.request.use(async (request) => {
  const token = await getUserToken();
  if (token) {
    request.headers = {
      Authorization: `Bearer ${token}`,
    };
  }
  return request;
});

const ASSESS_RESUME_ROUTE = "assess-resume";
const UPLOAD_ROLE_RESUMES = "resume-feeder";
const RESUME_UNPROCESSED = "resume-not-processed";
const ROLE_RESUME_STATUS = "resume-feeder-status";
const ASSESSMENT_STATUS_ROUTE = "assessment-status";
const PROCESS_RESUME_BASIC_ROUTE = "process-resume/basic";
const CONTACT_US = "contact-us";
const REQUIREMENTS_ASSESSMENT_ROUTE = "requirement-assessment";
const JOBS_ROUTE = "jobs";
const DOWNLOAD_FILE = "download-file";
const SIGNUP = "signup";
const DOES_EMAIL_EXIST = "does-email-exist";
const GENERATE_API_KEY = "generate-api-key";
const GET_API_KEY = "get-api-key";
const REVIEW_INFERENCE = "tools/review-inference";
const NEXT_RESUME_TO_QA = "/tools/next-resume-to-qa";
const NEXT_RESUME_TO_BUILD = "/tools/next-resume-to-build";
const PERSIST_PARSING_QA_RESULT = "/tools/persist-parsing-qa-result";
const CREATE_ROLE = "create-role";
const SAVE_ROLE = "save-role";
const GET_ROLES = "roles";
const GET_SKILLS = "skill_names";
const ASK_CHATGPT = "ask-chatgpt";
const GET_ATS_API_KEY = "/get-ats-api-key";
const SAVE_ATS_API_KEY = "/save-api-key";
const BILLING = "billing";
const CREATE_PAYMENT_INTENT = "create-payment-intent";
const ADD_CREDIT = "add-credit";
const RESUME_SUBMIT_CONTENT = "/tools/resume-submit-content";
const HONEST_RESUME = "honest-resume";
const FILTER_ASSESSMENTS = "filter-assessments";
const SUBMIT_MATCH = "submit-match";
const MATCHED_ASSESSMENTS = "matched-assessments";
const SAVE_NAME_HR = "save-name-hr";
const RESUME_ASSESSMENT = "resume-assessment";
const RESUMES_AROUND = "resumes-around";
const IS_RESUME_MATCHED = "is-resume-matched";
const GET_ACTIVE_ROLES = "active-roles";
const SEARCH_CANDIDATES = "search";
const GET_ALL_ROLES = "all-roles";
const DELETE_ROLE = "delete-role";
const UPDATE_ROLE = "update-role";
const GET_CANDIDATE = "candidate";
const SOURCING_CITIES = "sourcing-cities";
const COMPANIES_TO_SOURCE = "companies-to-source";
const READ_SOURCED_PROFILES = "read-sourced-profiles";
const READ_MESSAGED_PROFILES = "read-messaged-profiles";
const READ_SKIPPED_PROFILES = "read-skipped-profiles";
const READ_SOURCED_PROFILE_DETAILS = "read-sourced-profile-details";
const MARK_MESSAGED = "mark-messaged";
const WHY_PROFILE = "why-profile";
const SOURCED_ROLES = "sourced-roles";
const SET_SOURCED_ROLE_STATUS = "set-sourced-role-status";
const ADD_SORUCED_ROLE = "add-role";
const TITLES_TO_SOURCE = "titles-to-source";
const GET_US_STATES = "us-states";
const GET_US_CITIES = "us-cities";
const UPDATE_SOURCING_ROLE = "update-sourcing-role";
const SOURCED_ROLE_DETAILS = "sourced-role-details";
const TOOLS_READ_COMPANY = "tools/read-company";
const TOOLS_SET_COMPANY_CACHE = "tools/set-company-cache";
const TOOLS_PROFILE_INFO = "tools/profile-info";
const TOOLS_SET_PROFILE_URL = "tools/set-profile-url";
const TOOLS_USER_DAILY_URL_COUNT = "tools/user-daily-url-cnt";
const SKIP_PROFILE = "skip-profile";
const SAVE_MESSAGE_TEMPLATE = "save-message-template";
const PROFILE_SOURCING_MESSAGE = "profile-sourcing-message";
const READ_MESSAGE_TEMPLATE = "read-message-template";
const READ_IMPERSONATION = "read-impersonation";
const IMPERSONATE = "impersonate";
const CLEAR_IMPERSONATION = "clear-impersonation";


const ROLE_MAIN_KEYS = [
  "id", 
  "title", 
  "description", 
  "is_contract", 
  "client_name", 
  "pay_type", 
  "pay_amount", 
  "is_active"
];
const ROLE_FILTERS_KEYS = [
  "min_yoe",
  "max_yoe",
  "ic_min_yoe",
  "ic_max_yoe",
  "manager_min_yoe",
  "manager_max_yoe",
  "us_bachelors",
  "skip_bootcamp_grads",
  "us_citizen_gc_holder",
  "canadian_resident",
  "cs_or_stem_major",
  "atleast_one",
  "all_present",
  "nice_to_haves",
  "skills_and_yoe",
  "in_us_since",
  "us_states",
  "usa_remote_ok",
  "north_america_remote_ok",
  "anywhere_remote_ok",
  "early_stage_experience",
  "mid-late_stage_experience",
  "big_tech",
  "stack_focus",
  "requires_sponsorship",
  "was_promoted",
  "is_in_school",
  "had_internship"
];

export const getRequirementAssessment = async (job_id) => {
    const res = await API.get(REQUIREMENTS_ASSESSMENT_ROUTE,
      { params: { job_id } } 
    );
    return res.data;
}

export const reviewInference = async (resume_id, callback) => {
    const frontend_id = window?.crypto.randomUUID();
    callback(frontend_id);
    const res = await API.get(REVIEW_INFERENCE,
      { params: { resume_id, frontend_id } } 
    );
    const result = res.data;
    result?.work_experience?.forEach((role, i) => {
      if (role?.company_name && result?.work_experience[i-1]?.company_name === role?.company_name) {
        role.same_company = true;
      }
    });
    return res.data;
}

export const getJobs = async () => {
    const res = await API.get(JOBS_ROUTE);
    return res.data;
}
export const doesEmailExist = async (email) => {
    const res = await API.get(DOES_EMAIL_EXIST, {
      params: { email }
    });
    return res.data
}

export const assessmentStatus = async (frontend_id) =>  {
  const res = await API.get(ASSESSMENT_STATUS_ROUTE,
    { params: { frontend_id } } 
  );
  return res.data;
}

export const assessResume = async (file, callback) =>  {
    const formData = new FormData();
    formData.append("file", file);
    const uuid = window?.crypto.randomUUID();
    const blob = new Blob([uuid], { type: 'application/json' });
    formData.append("frontend_id", blob);
    try {
      const response = await API.post(ASSESS_RESUME_ROUTE, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: event => {
          if (event.loaded >= event.total) {
            callback(uuid);
          }
        }
      });
      const result = response.data;
      result?.work_experience?.forEach((role, i) => {
        if (role?.company_name && result?.work_experience[i-1]?.company_name === role?.company_name) {
          role.same_company = true;
        }
      });
      return result;
    } catch (error) {
        handleError(error);
        throw error;
    }
  }

export const contactUs = async (name, email, message) => {
  try {
      await API.post(CONTACT_US, { 
          name,
          email,
          message
      }, {
          headers: {  
              'Content-Type': 'application/json'
          }
      });
    } catch (error) {
      handleError(error);
    }  
}

export const createRole = async (title) => {
  const request = {
    title
  };
  try {
    const res = await API.post(CREATE_ROLE, request, {
      headers: {  
        'Content-Type': 'application/json'
      }
    });
    return { id: res.data.id, title, is_active: true };
  } catch (error) {
    handleError(error);
  }  
}

export const saveRole = async (data) => {
  const request = {};
  const filters = {};
  for (let key in data) {
    if (ROLE_MAIN_KEYS.includes(key) && data[key] !== '') {
      request[key] = data[key];
    } else if (ROLE_FILTERS_KEYS.includes(key) && data[key] !== '') { 
      filters[key] = data[key];
    }
  }
  request['filters'] = JSON.stringify(filters);
  try {
    await API.post(SAVE_ROLE, request, {
      headers: {  
        'Content-Type': 'application/json'
      }
    });
    return { ...data };
  } catch (error) {
    handleError(error);
  }  
}

export const getRoles = async (limit=100, offset=0) => {
  const res = await API.get(GET_ROLES, { params: { limit, offset } });
  if (res?.data) {
    const items =  res?.data.map(item => ({ ...item, ...item.filters }))
    return [...items.filter(item => item.is_active), ...items.filter(item => !item.is_active)]
  }
  return res.data;
}

export const getSkills = async () => {
  const res = await API.get(GET_SKILLS);
  return res?.data;
}

export const signup = async (email, full_name, company_name, title, firebase_id) => {
  try {
      await API.post(SIGNUP, { 
          email,
          full_name,
          company_name,
          title,
          firebase_id
      }, {
          headers: {  
              'Content-Type': 'application/json'
          }
      });
    } catch (error) {
      handleError(error);
    }  
}

export const openFileInNewTab = async (resume_s3_path, resume_file_name) => {
  try {
    const response = await API.get(DOWNLOAD_FILE, {
      params: { resume_s3_path, resume_file_name },
      responseType: 'blob'
    });
    window.open(URL.createObjectURL(response.data));
  } catch(error) {
    handleError(error);
  }
}

export const generateAPIKey = async () => {
  try {
      const res = await API.post(GENERATE_API_KEY);
      return res.data;
  } catch (error) {
      handleError(error);
      throw error
  }  
}

export const getAPIKey = async () => {
  try {
    const res = await API.get(GET_API_KEY);
    return res.data;
  } catch(error) {
      handleError(error);
      throw error;
  }
}

export const processResumeBasic = async (file) =>  {
    const formData = new FormData();
    formData.append("file", file);
    try {
      const response = await API.post(PROCESS_RESUME_BASIC_ROUTE, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        }
      });
      console.log(response.data);
      return response.data;
    } catch (error) {
        handleError(error);
    }
  }


const handleError = (error) => {
    if (error.response) { // get response with a status code not in range 2xx
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
      if (error.response && error.response.status === 401) {
        return "unauthorized";
      }
    } else if (error.request) { // no response
      console.log(error.request);
    } else { // Something wrong in setting up the request
      console.log('Error', error.message);
    }
    console.log(error.config);
}

export const uploadRoleResumes = async (roleId, files, callback) =>  {
  const formData = new FormData();
  const lastModified = {};
  for (let i = 0; i < files.length; i++) {
    lastModified[files[i].name] = files[i].lastModified;
    formData.append("file[]", files[i]);
  }
  console.log(lastModified);
  const blob = new Blob([roleId], { type: 'application/json' });
  formData.append("role_id", blob);
  const blob2 = new Blob([JSON.stringify(lastModified)], { type: 'application/json' });
  formData.append("last_modified", blob2);
  
  try {
    callback(0);
    const response = await API.post(UPLOAD_ROLE_RESUMES, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        callback(percentCompleted);
      }
    });
    return response.data;
  } catch (error) {
      handleError(error);
      throw error;
  }
}

export const getRoleResumeStatus = async (role_id, sort_option = 'date_of_upload') =>  {
  try {
    const res = await API.get(ROLE_RESUME_STATUS,
      { params: { role_id, sort_option } } 
    );
    return res.data;
  } catch (error) {
    handleError(error);
    throw error;
  }
}

export const getUnprocessedResumes = async (role_id) =>  {
  try {
    const res = await API.get(RESUME_UNPROCESSED,
      { params: { role_id } } 
    );
    return res.data?.resume_processing_failed;
  } catch (error) {
    handleError(error);
    throw error;
  }
}


export const askChatGPT = async (role_id, resume_id) =>  {
  try {
    const res = await API.get(ASK_CHATGPT,
      { params: { role_id, resume_id } } 
    );
    return res.data;
  } catch (error) {
      handleError(error);
      throw error;
  }
}

export const getNextResumeToQA = async () => {
  try {
    const res = await API.get(NEXT_RESUME_TO_QA);
    return res.data;
  } catch(error) {
      handleError(error);
      throw error;
  }
}

export const getNextResumeToBuild = async () => {
  try {
    const res = await API.get(NEXT_RESUME_TO_BUILD);
    return res.data;
  } catch(error) {
      handleError(error);
      throw error;
  }
}

export const resumeSubmitContent = async (resume_id, experience, education, skills_text, personalData) => {
  try {
      await API.post(RESUME_SUBMIT_CONTENT, { 
        resume_id, 
        experience, 
        education,
        skills_text,
        ...personalData
      }, {
        headers: {  
          'Content-Type': 'application/json'
        }
      });
    } catch (error) {
      handleError(error);
    }  
}


export const submitQAFeedback = async (resume_parsing_result_id, has_issues, notes) => {
  try {
    const res = await API.get(PERSIST_PARSING_QA_RESULT,
      { params: { resume_parsing_result_id, has_issues, notes } } 
    );
    return res.data;
  } catch(error) {
      handleError(error);
      throw error;
  }
}

export const getAtsApiKey = async () =>  {
  try {
    const res = await API.get(GET_ATS_API_KEY);
    return res.data;
  } catch (error) {
    handleError(error);
    throw error;
  }
}

export const saveAtsApiKey = async (ats_name, api_key) =>  {
  try {
    const res = await API.get(SAVE_ATS_API_KEY,
      { params: { ats_name, api_key } });
    return res.data;
  } catch (error) {
    handleError(error);
    throw error;
  }
}

export const getBillingData = async () => {
  try {
    const res = await API.get(BILLING);
    return res.data;
  } catch(error) {
      handleError(error);
      throw error;
  }
}

export const createPaymentIntent = async (id) => {
  try {
    const res = await API.get(
      CREATE_PAYMENT_INTENT,
      { params: { id } });
    return res.data;
  } catch (error) {
      handleError(error);
      throw error
  }  
}

export const addCredit = async (payment_id) =>  {
  try {
    const res = await API.get(ADD_CREDIT,
      { params: { payment_id } });
    return res.data;
  } catch (error) {
    handleError(error);
    throw error;
  }
}

export const getHonestResume = async (resume_id) =>  {
  try {
    const res = await API.get(HONEST_RESUME,
      { params: { resume_id } });
    return res.data;
    
  } catch (error) {
    handleError(error);
    throw error;
  }
}

export const filterAssessments= async (role_id, was_promoted,
  big_tech_experience, startup_experience,
  is_still_in_school) =>  {
  try {
    const res = await API.get(FILTER_ASSESSMENTS,
      { params: { role_id, was_promoted,
        big_tech_experience, startup_experience,
        is_still_in_school } } 
    );
    return res.data;
  } catch (error) {
    handleError(error);
    throw error;
  }
}

export const submitMatch = async (role_id, resume_id) =>  {
  try {
    const res = await API.get(SUBMIT_MATCH,
      { params: { role_id, resume_id } });
    return res.data;
    
  } catch (error) {
    handleError(error);
    throw error;
  }
}

export const getMatchedAssessments = async (role_id) =>  {
  try {
    const res = await API.get(MATCHED_ASSESSMENTS,
      { params: { role_id } });
    return res.data;
    
  } catch (error) {
    handleError(error);
    throw error;
  }
}

export const saveNameHr = async (resume_id, name_hr) =>  {
  try {
    const res = await API.get(SAVE_NAME_HR,
      { params: { name_hr, resume_id } });
    return res.data;
    
  } catch (error) {
    handleError(error);
    throw error;
  }
}

export const getResumeAssessment = async (role_id, resume_id) =>  {
  try {
    const res = await API.get(RESUME_ASSESSMENT,
      { params: { role_id, resume_id } });
    return res.data;
    
  } catch (error) {
    handleError(error);
    throw error;
  }
}

export const getResumesAround = async (role_id, resume_id) =>  {
  try {
    const res = await API.get(RESUMES_AROUND,
      { params: { role_id, resume_id } });
    return res.data;
    
  } catch (error) {
    handleError(error);
    throw error;
  }
}

export const isResumeMatched = async (role_id, resume_id) =>  {
  try {
    const res = await API.get(IS_RESUME_MATCHED,
      { params: { role_id, resume_id } });
    return res.data;
    
  } catch (error) {
    handleError(error);
    throw error;
  }
}

export const getAllRoles = async () => {
  try {
    const res = await API.get(GET_ALL_ROLES);
    if (res?.data) {
      const items =  res?.data.map(item => ({ ...item, ...item.filters }));
      return [...items.filter(item => item.is_active), ...items.filter(item => !item.is_active)]
    }
    return res.data;
  } catch (error) {
    handleError(error);
    throw error;
  }
}

export const getActiveRoles = async (limit=100, offset=0) => {
  try {
    const res = await API.get(GET_ACTIVE_ROLES, { params: { limit, offset } });
    if (res?.data) {
      const items =  res?.data.map(item => ({ ...item, ...item.filters }))
      return items || [];
    }
    return res.data;
  } catch (error) {
    handleError(error);
    throw error;
  }
}

export const searchCandidates = async (search_txt) => {
  const res = await API.get(SEARCH_CANDIDATES, { params: { search_txt } });
  if (res?.data) {
    return res?.data || [];
  }
  return res.data;
}

export const deleteRole = async (role_id) =>  {
  try {
    const res = await API.get(DELETE_ROLE,
      { params: { role_id } });
    return res.data;
    
  } catch (error) {
    handleError(error);
    throw error;
  }
}

export const updateRole = async (role_id, title) =>  {
  try {
    const res = await API.get(UPDATE_ROLE,
      { params: { role_id, title } });
    return res.data;
    
  } catch (error) {
    handleError(error);
    throw error;
  }
}

export const getCandidate = async (candidate_id) => {
  try {
    const res = await API.get(GET_CANDIDATE, { params: { candidate_id } });
    return res?.data || [];
  } catch (error) {
    handleError(error);
    throw error;
  }
}

export const getSourcingCities = async () => {
  try {
    const res = await API.get(SOURCING_CITIES);
    return res?.data?.cities || [];
  } catch (error) {
    handleError(error);
    throw error;
  }
}

export const getCompaniesToSource = async (data) => {
  const request = {
    location_ids: JSON.stringify(data.location || []),
    company_funding_stages: JSON.stringify(data.company_funding_stages || []),
    rating: data.rating,
    title_keywords: JSON.stringify(data.title_keywords || []),
    stack_skills: JSON.stringify(data.stack_skills || []),
    three_year_funding_anniversary: !!data.three_year_funding_anniversary,
    four_year_funding_anniversary: !!data.four_year_funding_anniversary
  }
  try {
    const res = await API.post(COMPANIES_TO_SOURCE, request);
    return res?.data;
  } catch (error) {
    handleError(error);
    throw error;
  }
}

export const getSourcedProfiles = async (role_id) => {
  try {
    const res = await API.get(READ_SOURCED_PROFILES, { params: { role_id } });
    return res?.data || [];
  } catch (error) {
    handleError(error);
    throw error;
  }
}

export const getMessagedProfiles = async (role_id) => {
  try {
    const res = await API.get(READ_MESSAGED_PROFILES, { params: { role_id } });
    return res?.data || [];
  } catch (error) {
    handleError(error);
    throw error;
  }
}

export const getSourcedProfileDetails = async (profile_id, role_id) => {
  try {
    const res = await API.get(READ_SOURCED_PROFILE_DETAILS, { params: { profile_id, role_id } });
    return res?.data || [];
  } catch (error) {
    handleError(error);
    throw error;
  }
}

export const markMessaged = async (profile_id, role_id) => {
  try {
    const res = await API.get(MARK_MESSAGED, { params: { profile_id, role_id } });
    return res?.data || [];
  } catch (error) {
    handleError(error);
    throw error;
  }
}

export const whyProfile = async (profile_id, role_id) => {
  try {
    const res = await API.get(WHY_PROFILE, { params: { profile_id, role_id } });
    return res?.data;
  } catch (error) {
    handleError(error);
    throw error;
  }
}

export const getSourcedRoles = async () => {
  try {
    const res = await API.get(SOURCED_ROLES);
    return res?.data || [];
  } catch (error) {
    handleError(error);
    throw error;
  }
}

export const setSourcedRoleStatus = async (role_id, is_active) =>  {
  try {
    const res = await API.get(SET_SOURCED_ROLE_STATUS,
      { params: { role_id, is_active } });
    return res.data;
    
  } catch (error) {
    handleError(error);
    throw error;
  }
}

export const addSourcedRole = async (data) =>  {
  try {
    const request = {name: data.name, title_id: data.title_id};
    const sourcing_filter = {...data};
    delete sourcing_filter.name;
    delete sourcing_filter.title_id;
    Object.keys(sourcing_filter).forEach(key => {
      if (!sourcing_filter[key]) {
        sourcing_filter[key] = 0;
      }
    });
    request.sourcing_filter = JSON.stringify(sourcing_filter);
    const res = await API.post(ADD_SORUCED_ROLE, request);
    return res.data;
  } catch (error) {
    handleError(error);
    throw error;
  }
}

export const updateSourcedRole = async (data, role_id) =>  {
  try {
    const request = {
      name: data.name,
      role_id
    };
    const sourcing_filter = { ...data };
    delete sourcing_filter.name;
    delete sourcing_filter.title_id;
    Object.keys(sourcing_filter).forEach(key => {
      if (!sourcing_filter[key]) {
        sourcing_filter[key] = 0;
      }
    });
    request.sourcing_filter = JSON.stringify(sourcing_filter);
    const res = await API.post(UPDATE_SOURCING_ROLE, request);
    return res.data;
  } catch (error) {
    handleError(error);
    throw error;
  }
}

export const titlesToSource = async () => {
  try {
    const res = await API.get(TITLES_TO_SOURCE);
    return res?.data;
  } catch (error) {
    handleError(error);
    throw error;
  }
}

export const getUSStates = async () => {
  try {
    const res = await API.get(GET_US_STATES);
    return res?.data;
  } catch (error) {
    handleError(error);
    throw error;
  }
}

export const getUSCities = async () => {
  try {
    const res = await API.get(GET_US_CITIES);
    return res?.data;
  } catch (error) {
    handleError(error);
    throw error;
  }
}

export const getSourcedRoleDetails = async (role_id) => {
  try {
    const res = await API.get(SOURCED_ROLE_DETAILS, { params: { role_id } });
    if (res?.data) {
      return ({name: res?.data.name, title_id: res?.data.title_id, ...res?.data.sourcing_filter})
    }
    return null;
  } catch (error) {
    handleError(error);
    throw error;
  }
}

export const apiReplayer = async (api, isSucceed, params={}, attemptLimit=5, index=1) => {
  try {
    const res = await api(params);
    if (isSucceed(res)) {
      return res;
    } else {
      throw new Error('Not expected API response');
    }
  } catch (err) {
    if (index <=  attemptLimit) {
      console.log(`[apiReplayer] Attempt ${index} failed`, err.message);
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(apiReplayer(api, isSucceed, params, attemptLimit, index+1));
        }, 500*Math.pow(2, index)*Math.random())
      })
    } else {
      console.log('[apiReplayer] All attempts failed');
      return null;
    }
  }
}

export const toolsReadCompany = async (company_name) => {
  try {
    const res = await API.get(TOOLS_READ_COMPANY, { params: { company_name } });
    return res?.data;
  } catch (error) {
    handleError(error);
    throw error;
  }
}

export const toolsSetCompanyCache = async (data) =>  {
  try {
    const request = { company_name: data.company_name };
    const attributes = {...data};
    delete attributes.company_name;
    request.attributes = JSON.stringify(attributes);
    const res = await API.post(TOOLS_SET_COMPANY_CACHE, request);
    return res.data;
  } catch (error) {
    handleError(error);
    throw error;
  }
}

export const toolsProfileInfo = async () => {
  try {
    const res = await API.get(TOOLS_PROFILE_INFO);
    return res?.data;
  } catch (error) {
    handleError(error);
    throw error;
  }
}

export const toolsSetProfileUrl = async (profile_id, profile_url) => {
  try {
    const res = await API.get(TOOLS_SET_PROFILE_URL, { params: { profile_id, profile_url } });
    return res?.data;
  } catch (error) {
    handleError(error);
    throw error;
  }
}

export const toolsGetDailyUrlCount = async () => {
  try {
    const res = await API.get(TOOLS_USER_DAILY_URL_COUNT);
    return res?.data;
  } catch (error) {
    handleError(error);
    throw error;
  }
}

export const skipProfile = async (data) =>  {
  try {
    const res = await API.post(
      SKIP_PROFILE, 
      data
    );
    return res.data;
  } catch (error) {
    handleError(error);
    throw error;
  }
}

export const getSkippedProfiles = async (role_id) => {
  try {
    const res = await API.get(READ_SKIPPED_PROFILES, { params: { role_id } });
    return res?.data || [];
  } catch (error) {
    handleError(error);
    throw error;
  }
}


export const saveMessageTemplate = async (data) =>  {
  try {
    const res = await API.post(
      SAVE_MESSAGE_TEMPLATE, 
      data
    );
    return res.data;
  } catch (error) {
    handleError(error);
    throw error;
  }
}

export const getProfileSourcingMessage = async (role_id, profile_id) => {
  try {
    const res = await API.get(PROFILE_SOURCING_MESSAGE, { params: { role_id, profile_id } });
    return res?.data || [];
  } catch (error) {
    handleError(error);
    throw error;
  }
}

export const readMessageTemplate = async () => {
  try {
    const res = await API.get(READ_MESSAGE_TEMPLATE);
    return res?.data || [];
  } catch (error) {
    handleError(error);
    throw error;
  }
}

export const readImpersonation = async () => {
  try {
    const res = await API.get(READ_IMPERSONATION);
    return res?.data;
  } catch (error) {
    handleError(error);
    throw error;
  }
}

export const impersonate = async (impersonation_email) =>  {
  try {
    const res = await API.post(
      IMPERSONATE, 
      {impersonation_email}
    );
    return res.data;
  } catch (error) {
    handleError(error);
    throw error;
  }
}

export const clearImpersonation = async () =>  {
  try {
    const res = await API.post(CLEAR_IMPERSONATION);
    return res.data;
  } catch (error) {
    handleError(error);
    throw error;
  }
}
import React, { useState } from "react";

const ProfileFeedback = ({ data, onSubmit, onCancel }) => {
  const [feedback, setFeedback] = useState('');
  const onSubmitClick = () => {
    onSubmit({...data, skip_reason: feedback});
  }

  return (
    <div 
      className="fixed inset-0 bg-black/30 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 w-full max-w-md mx-4">
        <h3 className="text-lg font-medium mb-4">
          Help us improve our AI screening to better source for you
        </h3>
        <p className="mb-4">Please provide the reason for skipping this candidate:</p>
        <textarea 
          className="w-full border border-gray-300 rounded p-2 mb-4 h-32"
          value={feedback}
          onChange={(e) => setFeedback(e.target.value)}
          placeholder="Enter your feedback here..."
        />
        <div className="flex justify-end space-x-2">
          <button 
            className="px-3 py-1 cursor-pointer text-sm border border-gray-300 rounded text-gray-700 hover:bg-gray-50"
            onClick={onCancel}
          >
            Cancel
          </button>
          <button 
            className="bg-green-600 cursor-pointer text-white px-3 py-1 rounded hover:bg-green-700"
            onClick={onSubmitClick}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  )
}

export default ProfileFeedback;
import React from "react";
import styled from 'styled-components';
import { useNavigate } from 'react-router';
import { Users, Filter, Settings, LogOut, HelpCircle, MessageCircle } from 'lucide-react';
import { logoutFirebaseUser } from "../services/firebase";
import LoadingScreen from "./LoadingScreen";
import Footer from "./FooterV2";

const Container = styled.div`
  .dashboard {
    position: sticky;
    height: 100vh;
    top: 0;
    left: 0;
  }
  .loading-screen-container {
    position: relative;
    height: calc(100vh - 20rem);
    width: 100%;
  }
  .loading-screen {
    background: white;
  }
`;

const renderIcon = (id) => {
  if (id === 'roles') return <Filter className="w-5 h-5" />;
  if (id === 'profiles') return <Users className="w-5 h-5" />;
  if (id === 'faq') return <HelpCircle className="w-5 h-5" />;
  if (id === 'settings') return <Settings className="w-5 h-5" />;
  if (id === 'message-template') return <MessageCircle className="w-5 h-5 mr-3" />;
  return null;
}

const FOOTER = [
  {id: 'faq', url: '/faq', name: 'FAQ'},
  {id: 'settings', url: '/settings', name: 'Settings'}
];

const PAGES = [
  {id: 'roles', url: '/sourcing-roles', name: 'Roles'},
  {id: 'profiles', url: '/sourcing', name: 'Profiles'},
  {id: 'message-template', url: '/message-template', name: 'Message Template'}
];

const TOOLS_PAGES = [
  {id: 'company_cache', url: '/tools/company_cache', name: 'Company Cache'},
  {id: 'profile_info', url: '/tools/profile_info', name: 'Profile Info'}
];

const PageContainer = ({ selected, title, loading, children, tools }) => {
  const navigate = useNavigate();

  const getClassname = (id) => {
    const base = 'w-full text-gray-700 flex cursor-pointer items-center px-4 py-2 hover:bg-green-100 hover:text-black'
    if (selected === id) return base + ' bg-green-200 text-white-700';
    return base;
  }

  const renderNavItem = (item) => {
    return (
      <button 
        onClick={() => navigate(item.url)}
        className={getClassname(item.id)}
        key={item.id}
      >
        <div className="flex items-center space-x-3 text-gray-700">
          {renderIcon(item.id)}
          <span>{item.name}</span>
        </div>
      </button>
    )
  }

  return (<div>
  <Container className="flex">
      <div className={`dashboard bg-gray-50 border-t border-r text-gray-800 transition-all duration-300 flex flex-col w-64`}>
        <div className="p-4 text-lg font-bold text-green-600">
          Honest Resume{tools ? ' Tools' : ''}
        </div>
        <nav className="mt-4 flex-grow">
          {
            tools ? 
            TOOLS_PAGES.map(item => renderNavItem(item)) :
            PAGES.map(item => renderNavItem(item)) 
          }
        </nav>
        <div className="border-t">
          { 
            !tools && 
            FOOTER.map(item => renderNavItem(item)) 
          }
          <button 
            onClick={logoutFirebaseUser}
            className="w-full flex cursor-pointer items-center px-4 py-2 hover:bg-red-100 text-gray-700"
          >
              <div className="flex items-center space-x-3 text-gray-700">
                <LogOut className="w-5 h-5" />
                <span>Logout</span>
              </div>
          </button>
        </div>
      </div>

      <div className="flex-grow bg-white overflow-y-auto relative">
        <div className="p-6" style={{minHeight: '100vh', maxHeight: loading ? '100vh' : 'auto'}}>
          <div className="mb-12">
            <h2 className="text-2xl font-bold">{title}</h2>
          </div>
          {
            loading ?
            <LoadingScreen loading={loading} 
              color="var(--color-green-700)" 
              secondaryColor="rgba(51,128,63,0.4)"/> :
            <>{ children }</>
          }   
        </div>
        
      </div>
   </Container>
   <Footer/>
   </div>
   );
};

export default PageContainer;